/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useParams}  from 'react-router-dom';
import Navbar from '../../../../components/navbar';

export default function  NavbarComponent() {
  
  const {id} = useParams()
  const routes= [
    {name:'Informacion', 'route':`/admin/servicios/${id}`},
  ]
  
  return (
    <>
    <Navbar routes={routes} />
    </>
  )
}
