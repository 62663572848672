/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../../components/modal";
import Builder from "../../../components/forms";
import { saveServiceAsync } from "../../../redux/service/actions";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { categoryApi } from "../../../api";
import SelectUser from "../../../components/select/user";
import useAuth from "../../../hooks/useAuth";

export const Create = () => {
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [categories, setCategories] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [userSelected, setUserSelected] = useState(false);

  const { AdminOrMe, Iam, user } = useAuth();

  useEffect(() => {
    if (!user?.id) return;

    let extra = [];

    if (Iam("administracion")) {
      extra.push({
        name: "active",
        label: "Activo",
        boolean: true,
      });
    }

    if (categories.length) {
      setFields([
        {
          name: "name",
          label: "Nombre",
          defaultValue: "",
          required: true,
        },
        ...extra,
        {
          name: "description",
          label: "Descripcion",
          defaultValue: "",
          required: true,
          textarea: true,
        },
        {
          name: "image",
          label: "Imagen",
          defaultValue: "",
          required: true,
          file: true,
        },
        {
          name: "category_id",
          label: "Categoria",
          defaultValue: categories[0].id,
          required: true,
          options: categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          )),
        },
      ]);
    }
  }, [user, categories]);

  useEffect(() => {
    categoryApi.get().then((res) => setCategories(res.data.data));
  }, []);

  const saveService = (data) => {
    data.user_id = AdminOrMe(userSelected);
    dispatch(saveServiceAsync(data));
  };

  const onChangeUser = (userS) => {
    setUserSelected(userS);
  };
  return (
    <>
      <Button onClick={() => setModalOpen(true)}> Crear servicio</Button>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={"Crear servicio"}
      >
        {Iam("administracion") && (
          <SelectUser role="barbero" onChange={onChangeUser} />
        )}
        <Builder
          fields={fields}
          message="Crear servicio"
          onClick={saveService}
        />
      </Modal>
    </>
  );
};
