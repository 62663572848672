import {authAxios} from './../config/axios';

class Role{
    get=(params)=>{
        return authAxios.get('/metadatas', {
            params
        });
    }   

    save=(data)=>{
        return authAxios.post('/metadatas', data);
    }

    keys=(id)=>{
        return authAxios.get(`/metadatas/keys`);
    }       
}


const role = (new Role());
export default role;