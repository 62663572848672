/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FormGroup, Label } from "reactstrap";
import { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale/es";
registerLocale("es", es);

export default function UserIndex({ data, setData, next }) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  const isDayActive = (date) => {
    const dayOfWeek = moment(date).format("dddd").toLowerCase();
    return data.barber?.jorney?.[dayOfWeek]?.active;
  };

  const dateValidator = (date) => {
    // Deshabilitar todos los martes
    const isActive = isDayActive(date);

   // const isTuesday = moment(date).format("dddd").toLowerCase() === "martes";
    return isActive ;
  };

  const onChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setData({
        ...data,
        date: formattedDate,
      });
      next();
    }
  };

  return (
    <>
      <h4 className="mb-4">Selecciona un día</h4>
      <DatePicker
        id="datePicker"
        selected={data.date ? moment(data.date).toDate() : null}
        onChange={onChange}
        filterDate={dateValidator}
        minDate={moment().toDate()}
        className="form-control w-100"
        placeholderText="Selecciona una fecha"
        locale="es"
      />
    </>
  );
}
