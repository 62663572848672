/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from '../../../../../components/modal';
import Builder from '../../../../../components/forms';
import { saveAddressAsync } from '../../../../../redux/user/actions';
import { useDispatch } from 'react-redux';
import {Button} from 'reactstrap';
import SelectMap from '../../../../../components/SelectMap'
export const Create = ({user_id}) => {

 
  const dispatch = useDispatch();
  const [address,setAddress]= useState({});
  const [modalOpen, setModalOpen] = useState(false);

  // useEffect(()=>{

  //     setFields([
  //     {
  //       name:'address',
  //       label:'Direccion',
  //       defaultValue:'',
  //       required:true
  //     },
  //     {
  //       name:'city',
  //       label:'Ciudad',
  //       defaultValue:'',
  //       required:true
  //     },      
  //     {
  //       name:'state',
  //       label:'Estado',
  //       defaultValue:'',
  //       required:true
  //     },      

  //     {
  //       name:'cp',
  //       label:'Codigo postal',
  //       defaultValue:'',
  //       required:true
  //     },      
  //     {
  //       name:'map',
  //       label:'Mapa de google ',
  //       defaultValue:'',
  //       required:false
  //     },   
  //     ])
    

  // },[]);


  const onSave =() =>{
    const parts = address.address.split(',');
    
    let address2 = parts[0] || '';
    let city = parts.length > 2 ? parts[1].trim() : '';
    let cp = parts.length > 2 ? parts[2].trim().split(' ')[0] : '';
    let state = parts.length > 3 ? parts[3].trim().split('.')[0] : '';

    // Ajustar la lógica para casos donde cp y city puedan estar juntos
    if (!/\d{5}/.test(cp)) {
        city = cp;
        cp = '';
    }

    let data = {
        ...address,
        address: address2.trim(),
        city,
        cp,
        state,
        user_id
    }

    dispatch(saveAddressAsync(data));
    setModalOpen(false)
  }


    return (
     <>
       <Button onClick={()=>setModalOpen(true)}> Crear Direccion</Button>
       <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={'Crear direccion'}


        >

        <SelectMap onSelect={setAddress}/>


        <Button onClick={onSave}>Guardar</Button>

         
       </Modal>
        
     </>
    )
  
}