import Builder from '../../components/forms';
import {Card} from 'reactstrap';
import {useDispatch} from 'react-redux';
import {register} from '../../redux/auth/actions';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

export default function Login()
{

	const navigate = useNavigate();

	const dispatch = useDispatch();


	const fields = [
	{
		name:'email',
		type:'email',
		label:'Email',
		required:true,
	},
	{
		name:'password',
		password:true,
		label:'Password',
		required:true,
	},
	{
		name:'password_confirmation',
		password:true,
		label:'Confirma tu Password',
		required:true,
	},

	];

	const onClick=(data)=>{
		if(data['password']!==data['password_confirmation']) {
			toast.error('Las password no son iguales');
			return;
		}
		dispatch(register(data));
	}



	return <div className="row  h-100 justify-content-center align-items-center">

	<div className="col-md-6">

	<Card className="p-3 shadow">

	 <h5 className="text-center card-title">Crear cuenta</h5>

	<Builder text="Registrarse" fields={fields} confirm={false} onClick={onClick}/>
	<span className="text-center cursor-pointer" onClick={()=>navigate('/recuperar-password')}>¿Olvidaste tu contraseña?</span>
	<span className="text-center cursor-pointer fw-bold" onClick={()=>navigate('/login')}>Login</span>

	</Card>

	</div>
	</div>
	
}