import { Label, Input } from "reactstrap";
import React, { useState, useEffect } from "react";
import { userApi } from "../../api";

const styleInput = {
  display: "flex",
  marginBottom: "5px",
};

export default function User({ onChange, role = null, user = null }) {
  const [users, setUsers] = useState([]);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    user?.id && setUsers([user, ...users]);
  }, [user, users, role]);

  const searchChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      searchUsers(e.target.value);
    }, 1000);

    setTimer(newTimer);
  };

  let filterByRole = (u) => {
    if (role) {
      return u.role?.name === role;
    }
    return u;
  };

  const searchUsers = (search) => {
    search.trim() !== "" &&
      userApi
        .get({
          s: search,
          with: "role",
        })
        .then((response) => {
          let res = response.data.data.filter(filterByRole);
          setUsers(res);
        });
  };

  const selectUser = (e) => {
    const user = users.filter((user) => user.id === e.target.value)[0];
    onChange(e.target.value, user);
  };

  return (
    <>
      <Label>Selecciona un usuario</Label>

      <div style={styleInput}>
        <Input
          style={{ marginRight: "5px" }}
          placeholder="Busca un usuario"
          onKeyUp={searchChanged}
        />
      </div>
      <Input
        defaultValue={user?.id || "-1"}
        type="select"
        onChange={selectUser}
      >
        <option disabled value="-1">
          Selecciona un usuario
        </option>
        {users.map((user) => (
          <option key={user.id} value={user.id}>
            {user.fullName} | {user.phone} | {user.email} {user?.role?.name}
          </option>
        ))}
      </Input>
    </>
  );
}
