import bg from '../../../assets/bg.jpg'
import {useEffect, useState} from 'react';
import { userApi} from '../../../api';
import '../styles.scss';
import Pagination from '../../../components/datatable/paginate';
import CardBarberItem from './CardBarberItem';

function App() {

  const [barbers, setBarbers] = useState([]);
  const [page, setPage] = useState(1)

  useEffect(()=>{
   userApi.barbers({
      page,
      perPage:8
    }).then(res=>{
      setBarbers(res.data.data);
    });
  },[page])


  
  return (
    <>
    <div style={{backgroundImage:`url(${bg})`, height:'40vh', backgroundSize:'cover'}}>
      <div className="h-100" style={{backgroundColor:"#222020cc"}}>
        <div className="container h-100" >

          <div className="row justify-content-center align-items-center h-100 text-white text-center">
            <div className="col-md-6">
                
                
            <h1 className="text-white h1">Nuestros profesionales</h1>
            <h2 className="text-white lead">Busca entre nuestros barberos profesionales</h2>

            </div>     


          </div>
        </div>
      </div>

    </div>


    <div className="container  my-10 text-center">

  
      <div className="row justify-content-around">

      {barbers?.data?.map(barber=><CardBarberItem  barber={barber}/>)}

      </div>
      <div className="row my-10 justify-content-around">
        <Pagination count={barbers?.pages || 0} page={page} onChange={(e)=>setPage(e)} />

     </div>
    </div>
        
    </>
  );
}

export default App;
