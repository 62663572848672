import React from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = (lat, lng) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng),
});

const Map = ({ address }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_API_MAPS, // La API key está en el archivo .env
  });

  if (!isLoaded) return <div>Cargando mapa...</div>;

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center(address?.lat, address?.lng)}
        zoom={15}
      >
        <Marker position={center(address?.lat, address?.lng)} />
      </GoogleMap>

      <br/>
      <p className="lead">{address?.address}</p>
    </>
  );
};

export default Map;
