import React, { useEffect, useState } from "react";
import { appointmentApi, reviewApi } from "../../../api";
import { Card, CardBody, Button, Input, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import CardBarber from "../../web/Professionals/CardBarber";
import CardAddress from "../users/Show/Address/CardAddress";
import useAuth from "../../../hooks/useAuth";
import { FaStar } from "react-icons/fa6";
import classnames from 'classnames';
import Map from '../../../components/map'
export default function UserIndex({ containerClass = "container-fluid mt-3" }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [appointment, setAppointment] = useState({});
  const [reload, setReload] = useState(1);
  const { Iam, user: auth } = useAuth();
  const [review, setReview] = useState({
    stars: 0,
    comment: "",
  });
  const [activeTab, setActiveTab] = useState('service'); // Estado para la pestaña activa

  useEffect(() => {
    appointmentApi.show(id).then((res) => {
      console.log(res.data.data);
      setAppointment(res.data.data);
    });
  }, [id, reload]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const renderReview = () => {
    return (
      <>
        <br />
        <div className="">
          <FaStar
            className={
              appointment?.review?.stars >= 1 ? "star-yellow" : "star-gray"
            }
          />
          <FaStar
            className={
              appointment?.review?.stars >= 2 ? "star-yellow" : "star-gray"
            }
          />
          <FaStar
            className={
              appointment?.review?.stars >= 3 ? "star-yellow" : "star-gray"
            }
          />
          <FaStar
            className={
              appointment?.review?.stars >= 4 ? "star-yellow" : "star-gray"
            }
          />
          <FaStar
            className={
              appointment?.review?.stars >= 5 ? "star-yellow" : "star-gray"
            }
          />
        </div>

        <p className="mt-2">{appointment.review.comment} </p>
      </>
    );
  };

  const onChange = (value, name = "stars") => {
    setReview({
      ...review,
      [name]: value,
    });
  };

  const submit = () => {
    if (appointment.client_id !== auth.id) {
      alert("No tienes permitido hacer eso");
      return;
    }

    reviewApi
      .save({
        ...review,
        appointment_id: appointment.id,
      })
      .then((res) => {
        setReload(reload + 1);
      });
  };

  const renderFormReview = () => {
    if (!Iam("cliente")) return "No hay review";

    return (
      <div className="">
        <br />
        <div className="text-center ta-center">
          <FaStar
            onClick={() => onChange(1)}
            className={review.stars >= 1 ? "star-yellow" : "star-gray"}
          />
          <FaStar
            onClick={() => onChange(2)}
            className={review.stars >= 2 ? "star-yellow" : "star-gray"}
          />
          <FaStar
            onClick={() => onChange(3)}
            className={review.stars >= 3 ? "star-yellow" : "star-gray"}
          />
          <FaStar
            onClick={() => onChange(4)}
            className={review.stars >= 4 ? "star-yellow" : "star-gray"}
          />
          <FaStar
            onClick={() => onChange(5)}
            className={review.stars >= 5 ? "star-yellow" : "star-gray"}
          />

          <p className="my-4">Agrega un comentario </p>
        </div>

        <Input
          onChange={(e) => onChange(e.target.value, "comment")}
          type="textarea"
        />

        <Button onClick={submit} color="success">
          Guardar{" "}
        </Button>
      </div>
    );
  };

  return (
    <div className={containerClass}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'service' })}
            onClick={() => toggleTab('service')}
          >
            Servicio
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'barber' })}
            onClick={() => toggleTab('barber')}
          >
            Barbero
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === 'client' })}
            onClick={() => toggleTab('client')}
          >
            Cliente
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="service">
          <div className="row">
            <div className="col-md-12">
              <h4 className="my-4">Servicio</h4>
                            {!Iam("cliente") && (
                <Button
                  onClick={() => navigate(`/admin/citas/editar/${id}`)}
                  className="mb-3"
                  color="primary"
                >
                  Editar Cita{" "}
                </Button>

              )}
              <Card>
                <CardBody>
                  <p className="mt-3">
                    <span className="fw-bold">Dia:</span> {appointment.date}
                  </p>
                  <p className="mt-3">
                    <span className="fw-bold">Hora:</span> {appointment.time}
                  </p>
                  <p className="mt-3">
                    <span className="fw-bold">Total:</span>${appointment.price}
                  </p>
                </CardBody>
              </Card>
            <div className="row">
                   {appointment?.fees?.map((fee) => (
                    <div className=" col-md-4  col-sm-6 col-xs-12" key={fee.id} >
                      <Card>
                        <CardBody>
                          <img className="img-fluid" src={fee?.service?.image} alt="" />
                          <p className="mt-3">
                            <span className="fw-bold">Servicio:</span> {fee?.service?.name}
                          </p>
                          <p className="mt-3">
                            <span className="fw-bold">Tarifa: </span>{fee?.title}
                            <span className="fw-bold"> Minutos: </span>{fee?.minutes}
                          </p>
                          <p className="mt-3">
                            <span className="fw-bold">Precio:</span>${fee?.price}
                          </p>
                          <div className="mt-3">
                            <span className="fw-bold">Descripción:</span>
                            <div>{fee?.description}</div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </div>

              <Card>
                <CardBody>
                  <h6>Comentarios:</h6>
                  {appointment?.description}
                </CardBody>
              </Card>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="barber">
          <div className="row">
            <div className="col-md-12">
              <h4 className="my-4">Barbero</h4>

              <CardBarber barber={appointment.barber} />
            </div>
          </div>
        </TabPane>
        <TabPane tabId="client">
          <div className="row">
            <div className="col-md-12">
              <h4 className="my-4">Cliente</h4>
              {appointment.client && (
                <Card>
                  <CardBody>
                    <img className="img-fluid" src={appointment.client?.image} alt="" />
                    <p className="mt-3">
                      <span className="fw-bold">Cliente:</span> {appointment.client?.fullName}
                    </p>
                    <p className="mt-3">
                      <span className="fw-bold">Telefono:</span> {appointment.phone || 'No tiene'}
                    </p>
                  </CardBody>
                </Card>
              )}
              {appointment.status === "terminada" && (
                <Card>
                  <CardBody>
                    <div className="mt-3">
                      <span className="fw-bold">Review:</span>
                      {appointment.review ? renderReview() : renderFormReview()}
                    </div>
                  </CardBody>
                </Card>
              )}
              {appointment.address && <Map address={appointment.address} />}
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}
