import React from 'react';
import './index.styles.scss';
import {FaCheck, FaPen} from 'react-icons/fa6'
export default function Steps ({steps, current=1}) {

 

  const getClassNumber = (index) =>{
    if(index+1 < current) {
      return 'step-ok';
    }
    if(index+1 === current) {
      return 'step-current';
    }

    return '';
  }


  const getClassText = (index) =>{
    if(index+1 < current) {
      return '';
    }
    if(index+1 === current) {
      return 'step-text-current';
    }

    return '';
  }


  const getNumber = (index) =>{
    if(current ===index+1) {
      return <FaPen/>
    }
    if(current <index+1) {
      return index+1
    }
    
    if(current>index+1) {
      return <FaCheck/>
    }
  }
  return (
    <>
   
      <div className="row mb-5">
      {current && steps.map((step,index)=>
        (<div key={index} className="step col text-center">
          <span className={'step-number '+getClassNumber(index)}> {getNumber(index)}</span> <br/> <span className={'step-text '+getClassText(index)}>{step}</span>
          </div>
          )


      )}
      </div>


    </>
  )
}
