import React, {useState} from "react"
import ConfirmDialog from '../modal/ConfirmDialog';
import {useDispatch} from 'react-redux';
import {FaTrash} from 'react-icons/fa6';

const Delete=({dispatchable,id,style=false, message='Eliminar registro'})=> {
    
    const dispatch = useDispatch();


    const onClick = ()=>{
      dispatch(dispatchable(id))
    }

    const handleClick = ()=>{
        setModalOpen(true);        
    }


    const [modalOpen, setModalOpen] = useState(false);
    return (
      <>
        <FaTrash onClick={handleClick} style={style || {"cursor":"pointer",color:"#E83F2D",'margin':"0 5px"}} />

         <ConfirmDialog

            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            message={message}
            onClick={onClick}
          />

      </>
    )
  
  }

export default Delete