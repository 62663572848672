/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal';
import Builder from '../../../components/forms';
import { saveCategoryAsync } from '../../../redux/category/actions';
import { useDispatch } from 'react-redux';
import {Button} from 'reactstrap';

export const Create = ({id=false}) => {

 
  const dispatch = useDispatch();
  const [fields,setFields]= useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(()=>{

      setFields([
      {
        name:'name',
        label:'Nombre',
        defaultValue:'',
        required:true
      },
      {
        name:'description',
        label:'Descripcion',
        required:true,
        textarea:true
      },

      {
        name:'image',
        label:'Imagen',
        defaultValue:'',
        required:true,
        file:true
      },



      ])

  },[]);


  const saveCategory = (data)=>{

    if(id) {
      data.category_id=id
    }

    dispatch(saveCategoryAsync(data));
  }

    return (
     <>
       <Button onClick={()=>setModalOpen(true)}> Crear categoria</Button> 
       <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={'Crear categoria'}


        >
        <Builder fields={fields}   message='Crear categoria' onClick={saveCategory} />
         
       </Modal>
        
     </>
    )
  
}