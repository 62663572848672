import {  Badge} from 'reactstrap'
import {useEffect, useState} from 'react';
import { userApi, categoryApi, serviceApi} from '../../../api';
import '../styles.scss';
import {useNavigate, useParams} from 'react-router-dom';
import Pagination from '../../../components/datatable/paginate';
import CardBarber from './CardBarber';
import CardService from '../Services/CardService';

function App() {

  const  {id} = useParams();
  const [barber, setBarber] = useState([]);
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const  [category, setCategory] = useState(false);
  const [page, setPage] = useState(1)
 
  useEffect(()=>{
    userApi.barbers({
      id
    }).then(res=>{
      if(res.data.data.length) {
        setBarber(res.data.data[0]);
      } else {
        navigate('/404')
      }
    });
  },[id, navigate])


  useEffect(()=>{
    categoryApi.get().then(res=>{
      setCategories(res.data.data);
    });
  },[id])



  useEffect(()=>{

    let params = {
      page,
      with:'barber',
      user_id: id
    }

    if(category) {
      params.category_id= category.id
    }

    serviceApi.get(params).then(res=>{
      setServices(res.data.data);
    });
  },[page,id, category])



  return (
    <>
      <div style={{maxWidth:1280}}className="container my-10">
        <div className="row">
        <div className="col-lg-3 mb-5">

          <CardBarber barber={barber} />
         
         
      </div>
        <div className="col-lg-9">
        <p>Categorias</p>
         <Badge className={!category ? 'current' : ''} onClick={()=>setCategory(false)} color="secondary">Todas</Badge>
       
          {categories.map((cat) => <Badge className={cat.id===category.id? 'current' : ''} onClick={()=>setCategory(cat)} color="secondary">{cat.name}</Badge>)}
        
            <hr/>
    
          <p>Servicios ({services?.rows || 0})</p>

            <Pagination count={services?.pages || 0} page={page} onChange={(e)=>setPage(e)} />
            <br/>
        {services?.data?.map(service=><CardService service={service} />)}

      

        <Pagination count={services?.pages || 0} page={page} onChange={(e)=>setPage(e)} />

      </div>
      </div>
      </div>
    
        
    </>
  );
}

export default App;
