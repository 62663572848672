import {  createReducer } from "@reduxjs/toolkit";
import types from './actionTypes';

const initialState = {
    users:[],
    addresses:[],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.setUsers, (state, action) => {
      state.users = action.payload;
    }) 

    .addCase(types.setAddresses, (state, action) => {
      state.addresses = action.payload;
    }) 
});