/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { getAppointmentsAsync, updateAppointmentStatusAsync } from '../../../redux/appointment/actions'
import DataTables from '../../../components/datatable';
import { Edit, Show} from '../../../components/buttons';
import {Card, Button} from 'reactstrap';
import Navbar from './navbar';
import useAuth from '../../../hooks/useAuth';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export default function UserIndex({mode="admin", containerClass="container-fluid"}) {
  

  const selectable = (state)=>(state.appointment.appointments);
  const navigate = useNavigate();
  const {Iam, user} = useAuth();
  const dispatch = useDispatch();

  const changeStatus = (row, status) =>{
    dispatch(updateAppointmentStatusAsync(row.id, status));

  }
  const columns = (row)=>{



    let preRoute = mode==="admin"? '/admin/':'/';

    let options = false;



    if(!Iam('cliente')) {
      options=true;
    }

    return {
      dia: row?.date,
      hora: row?.time,
      status: row?.status,
      servicio: row?.fees[0]?.service?.name,
      precio: '$'+row?.price,
      review: 
        row.review ? 
          (row?.review?.stars) +' /5' : 
          ( (!options && row.status==='terminada'  ) ?
           (<Button color="outline-success"  onClick={()=>navigate(`${preRoute}citas/${row.id}`)} size="sm">Calificar</Button>) :
           '-')
      ,
      opciones:options? (
        <div className="btn-options">
          {options && row.status==='pendiente' && <Button color="outline-success"  size="sm" onClick={()=>changeStatus(row, 'aceptada')}>Aceptar</Button>}
          {options && row.status==='pendiente' && <Button  color="outline-danger" size="sm" onClick={()=>changeStatus(row, 'cancelada')}>Cancelar</Button>}
          {options && row.status==='aceptada' && (<Button color="outline-danger"  size="sm" onClick={()=>changeStatus(row, 'cancelada')}>Cancelar</Button>)}
          {options && row.status==='aceptada' && (<Button color="outline-success"  size="sm" onClick={()=>changeStatus(row, 'terminada')}>Terminar</Button>)}
          <Show route={`${preRoute}citas/${row.id}`} />          
          <Edit route={`${preRoute}citas/editar/${row.id}`} />
        </div>
      ):     
      (<div>
          {options && row.status==='pendiente' && <Button  color="outline-danger" size="sm" onClick={()=>changeStatus(row, 'cancelada')}>Cancelar</Button>}
          <Show route={`${preRoute}citas/${row.id}`} />          
        </div>
      ),
    }
  } 

  const dispatchable=(query)=>{

    let params = {
      ...query,
      with:'fees.service|review',
    }

    
    if(Iam('barbero')) {
      params.user_id= user.id
    }

    if(Iam('cliente')) {
       params.client_id=user.id
    }


      return getAppointmentsAsync(params);      


  } 


    return (

      <>

        <Navbar mode={mode}/>

        <div className={containerClass} >
      <Card >


        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          selectable={selectable}
          title='Gestion de citas'
        />
      


      </Card>

      </div>
      </>
    )
  
}