/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { jorneyApi, metadataApi } from "../../../api";
import {
  Input,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Confirm } from "../../../components/buttons";
import { toast } from "react-toastify";
import SelectUser from "../../../components/select/user";
import useAuth from "../../../hooks/useAuth";
import classnames from "classnames";

export default function UserIndex() {
  const [keys, setKeys] = useState({});
  const { user, Iam } = useAuth();
  const [data, setData] = useState({});
  const [ready, setReady] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  let [jorney, setJorney] = useState([]);
  const [schedule, setSchedule] = useState({
    monday: { start: "", end: "", active: false },
    tuesday: { start: "", end: "", active: false },
    wednesday: { start: "", end: "", active: false },
    thursday: { start: "", end: "", active: false },
    friday: { start: "", end: "", active: false },
    saturday: { start: "", end: "", active: false },
    sunday: { start: "", end: "", active: false },
  });

  const [labels] = useState({
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
  });

  useEffect(() => {
    if (!user?.id) return;

    metadataApi.keys().then((res) => {
      setKeys(res.data.data);
      setData(res.data.data);
      setReady(true);
    });

    if (Iam("barbero")) {
      setUserSelected(user.id);
    }
  }, [user]);

  useEffect(() => {
    let userSearch = user?.id;

    if (Iam("administracion")) {
      userSearch = userSelected;
    }
    userSearch &&
      ready &&
      metadataApi
        .get({
          user_id: userSearch,
        })
        .then((res) => {
          let newData = JSON.parse(JSON.stringify(data));
          for (let k in res.data.data) {
            let metadata = res.data.data[k];

            newData[metadata.data] = metadata.value;
          }

          setData(newData);
        });
  }, [user, userSelected, ready]);

  useEffect(() => {
    userSelected &&
      jorneyApi.get(userSelected).then((res) => {
        setJorney(res.data.data);
      });
  }, [userSelected]);

  useEffect(() => {
    const fillKeys = () => {
      setSchedule({
        monday: jorney.monday,
        tuesday: jorney.tuesday,
        wednesday: jorney.wednesday,
        thursday: jorney.thursday,
        friday: jorney.friday,
        saturday: jorney.saturday,
        sunday: jorney.sunday,
      });
    };

    jorney?.id && fillKeys();
  }, [jorney]);

  const status = [
    "pendiente",
    "aceptada",
    "rechazada",
    "cancelada",
    "terminada",
    "perdida",
  ];

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onTimeChange = (day, timeType, value) => {
    setSchedule({
      ...schedule,
      [day]: {
        ...schedule[day],
        [timeType]: value,
      },
    });
  };

  const onToggleDay = (day) => {
    setSchedule({
      ...schedule,
      [day]: {
        ...schedule[day],
        active: !schedule[day].active,
      },
    });
  };

  const renderKey = (key) => {
    let input = [];

    let labels = {
      appointment_status: "Estado inicial de la cita",
      appointment_time: "Mostrar citas cada tantos Minutos",
      appointment_margin: "Minutos entre citas",
    };

    input.push(<label>{labels[key]} </label>);
    if (key === "appointment_status") {
      input.push(
        <Input
          type="select"
          key={key}
          name={key}
          onChange={onChange}
          value={data[key]}
        >
          {status.map((stat) => (
            <option key={stat}>{stat}</option>
          ))}
        </Input>
      );
    } else {
      input.push(
        <Input key={key} name={key} onChange={onChange} value={data[key]} />
      );
    }

    input.push(
      <Confirm
        key={`${key}-confirm`}
        onClick={() => onSave(key)}
        text="Guardar"
      />
    );

    return <div key={key}>{input}</div>;
  };

  const onSave = (key) => {
    metadataApi
      .save({
        data: key,
        value: data[key],
      })
      .then((_res) => {
        toast.success("Configuracion guardada correctamente");
      });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const saveJorney = () => {
    let data = {
      barber_id: userSelected,
      ...schedule,
    };




      if(jorney.id){
        return jorneyApi.update(jorney.id, data).then((_res) => {
          toast.success("Horarios guardados correctamente");
        });
      }else {
        return jorneyApi.save(data).then((_res) => {
          toast.success("Horarios guardados correctamente");
        });  
      }

  };

  return (
    <div className="p-3">
      {Iam("administracion") && (
        <SelectUser role="barbero" onChange={setUserSelected} />
      )}
      <br />
      {userSelected && (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => toggleTab("1")}
              >
                Configuración
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => toggleTab("2")}
              >
                Horarios
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {keys && Object.keys(keys).map(renderKey)}
            </TabPane>
            <TabPane tabId="2">
              <div className="mt-4">
                <FormGroup>
                  {Object.keys(schedule).map((day) => (
                    <Row key={day} className="align-items-center mb-2">
                      <Col xs="2">
                        <strong>{labels[day]}</strong>
                      </Col>
                      <Col xs="2">
                        <Input
                          type="switch"
                          checked={schedule[day].active}
                          onChange={() => onToggleDay(day)}
                        />
                      </Col>
                      <Col xs="4">
                        <Input
                          type="time"
                          value={schedule[day].start}
                          onChange={(e) =>
                            onTimeChange(day, "start", e.target.value)
                          }
                          disabled={!schedule[day].active}
                        />
                      </Col>
                      <Col xs="4">
                        <Input
                          type="time"
                          value={schedule[day].end}
                          onChange={(e) =>
                            onTimeChange(day, "end", e.target.value)
                          }
                          disabled={!schedule[day].active}
                        />
                      </Col>
                    </Row>
                  ))}
                </FormGroup>

                <Button color="primary" onClick={saveJorney}>
                  Guardar horarios
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </>
      )}
    </div>
  );
}
