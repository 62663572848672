/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from 'react';
import {Input, Button} from 'reactstrap';
import SelectMap from '../../../../components/SelectMap';

export default function UserIndex({data, setData, next}) {


  const noAddress=()=>{

    setData({
      ...data,
      "address":null,
    })
    next()
  }

  const selectedAddress=(ad)=>{
    setData({
      ...data,
      address:ad
    })
  }

  return (
    <>
      <h3 className="mb-4">Selecciona una dirección</h3>

     

      <SelectMap onSelect={selectedAddress}>
        <Button className="mt-3 ml-2" onClick={noAddress}>Continuar sin dirección</Button>

      </SelectMap>

    </>
  );
}
