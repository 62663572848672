import React, {useEffect, useState} from 'react';
import {Button} from 'reactstrap';
import {userApi} from '../../../api';
import CardBarberItem from '../Professionals/CardBarberItem';
              
     

export default function Hero()
{


    const [barbers, setBarbers] = useState([]);


    useEffect(()=>{

        userApi.randomBarbers().then(res=>setBarbers(res.data.data));
    }, [])

	return  <div className="container-fluid py-10 text-center" style={{backgroundColor:'#fcf9f5'}}>


        <h5>Conoce a nuestros barberos</h5>
        <h2 className="text-primary">Elige aquel que se acomode a tus necesidades</h2>
        <Button color="primary">Ver más</Button>

        <div className="container">

          <div className="row  mt-5 justify-content-around">


      {barbers?.map(barber=><CardBarberItem  barber={barber}/>)}

      </div>
      </div>
    </div>
        
}