import { Badge } from "reactstrap";
import { useEffect, useState } from "react";
import { categoryApi, serviceApi } from "../../../api";
import "../styles.scss";
import { useSearchParams } from "react-router-dom";
import Pagination from "../../../components/datatable/paginate";
import CardService from "./CardService";

function App() {
  const [sp] = useSearchParams();
  const [services, setServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    categoryApi.get().then((res) => {
      setCategories(res.data.data);

      let category_id = sp.get("category_id") || false;
      if (category_id) {
        let find =
          res.data.data.filter((cat) => cat.id === category_id)[0] || false;

        if (find) {
          setCategory(find);
        }
      }
    });
  }, [sp]);

  useEffect(() => {
    let params = {
      page,
      active: 1,
      with: "barber",
    };

    if (category) {
      params.category_id = category.id;
    }

    serviceApi.get(params).then((res) => {
      setServices(res.data.data);
    });
  }, [page, category]);

  return (
    <>
      <div style={{ maxWidth: 1280 }} className="container my-10">
        <div className="row">
          <div className="col-md-12">
            <p>Categorias</p>
            <Badge
              className={!category ? "current" : ""}
              onClick={() => setCategory(false)}
              color="secondary"
            >
              Todas
            </Badge>

            {categories.map((cat) => (
              <Badge
                className={cat.id === category.id ? "current" : ""}
                onClick={() => setCategory(cat)}
                color="secondary"
              >
                {cat.name}
              </Badge>
            ))}

            <hr />

            <p>Servicios ({services?.rows || 0})</p>

            <Pagination
              count={services?.pages || 0}
              page={page}
              onChange={(e) => setPage(e)}
            />
            <br />

            <div className="row justify-content-center justify-content-xl-start">
              {services?.data?.map((service) => (
                <CardService service={service} />
              ))}
            </div>

            <Pagination
              count={services?.pages || 0}
              page={page}
              onChange={(e) => setPage(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
