import { authAxios } from "./../config/axios";

class Jorney {
  get = (user_id) => {
    return authAxios.get("/jorneys/" + user_id);
  };

  save = (data) => {
    return authAxios.post("/jorneys", data);
  };

  update = (id, data) => {
    return authAxios.put("/jorneys/" + id, data);
  };
}

const api = new Jorney();
export default api;
