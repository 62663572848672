/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersAsyc,  banUserAsync, unbanUserAsync, unlockUserAsync } from '../../../redux/user/actions'
import DataTables from '../../../components/datatable';
import { Show} from '../../../components/buttons';
import {Card} from 'reactstrap';
import {FaLock, FaUnlock} from 'react-icons/fa6';
import { Create} from './Create';
import {Input} from 'reactstrap';
import {getRolesAsync} from '../../../redux/role/actions';

export default function UserIndex() {
  


  const  roles  = useSelector((state)=>state.role.roles);
  const [role, setRole] = useState(1);
  const dispatch = useDispatch()

  const ban=(id)=>{
    dispatch(banUserAsync(id))
  }

  const unban=(id)=>{
    dispatch(unbanUserAsync(id))
  }


  const unlock=(id)=>{
    dispatch(unlockUserAsync(id))
  }


  useEffect(()=>{
    dispatch(getRolesAsync())
  },[])

  const selectable = (state)=>(state.user.users);

  const columns = (row)=>{
    return {
      nombre: row?.name,
      apellido: row?.lastname,
      email:row?.email,
      role: row?.role?.name,
      bloqueado:
        row.block_until!=null ? 
        <>
          {row.block_until}
          <FaLock onClick={()=>unlock(row.id)} style={{color:'red',cursor:'pointer'}} />
        </> : 'No',
      baneado: row.is_active ? (<FaUnlock onClick={()=>ban(row.id)}  style={{color:'green',cursor:'pointer'}} />) : <FaLock onClick={()=>unban(row.id)} style={{color:'red',cursor:'pointer'}} />,
      opciones:(
        <div>
          <Show route={`/admin/usuarios/${row.id}`} />
        </div>
      )
    }
  } 

  const dispatchable=(query)=>{


    if(role!==1) {
       return getUsersAsyc({
        ...query,
        select:'id,email,is_active,block_until, role_id,name, lastname',
        role_id:role,
      });   
    }

    return getUsersAsyc({
      ...query,
      select:'id,email,is_active,block_until, role_id,name, lastname',
    });
  } 


    return (
      <Card >


        <Create />


        <Input type="select" onChange={(e)=>setRole(e.target.value)}> 
          <option value={1} key="1">--Todos--</option>
          {roles.map(role=> <option key={role.id} value={role.id}>{role.name}</option>)}

        </Input>

        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          fire={role}
          selectable={selectable}
          title='Gestion de usuarios'
        />
        


      </Card>
    )
  
}