import Navbar from './components/navbar'
import Footer from './components/footer';
import './Web.styles.scss';
import {useSelector} from 'react-redux';
import {authApi} from '../api';
import {toast} from 'react-toastify';
import {useState, useEffect} from 'react';
import moment from 'moment'

   
export default function Login({children})
{
	const  user = useSelector((state)=>state.auth.user);

	const [showBody, setShowBody] = useState(true);


	useEffect(()=>{


		if(!user.email_verified_at) {
			let startdate = moment().subtract('1', 'days');
			let created_at=moment(user.created_at);
			setShowBody(!created_at.isBefore(startdate))

		}
	
	},[user])

	const reenviarCorreo = ()=>{
		console.log(user)
		authApi.sendVerification(user.email).then(res=>{
			toast.success('Email enviado correctamente');
		})

	}
	return <div className="web h-100" >
	<Navbar />

		{user.email_verified_at ===null && <div className="alert alert-info m-0">Verifica tu correo electronico <button onClick={reenviarCorreo} className="btn btn-link">Reenviar correo</button></div>}

		{showBody &&
		<div  className="overflow-scroll">
			{children}
		</div>
	}

	<Footer />
	</div>


}