import Show from '../../admin/users/Show'
function Profile() {


  return (
    <>
    

      <Show  mode="profile" containerClass="container"/>
     
    </>
  );
}

export default Profile;
