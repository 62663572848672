import { Card} from 'reactstrap'
import {useEffect, useState} from 'react';
import {categoryApi} from '../../api';
import './styles.scss';
import {useNavigate} from 'react-router-dom';
import Hero from './index/hero';
import OurBarbers from './index/barbers';
import AboutUs from './index/about';
import ContactForm from './index/contactForm';
function App() {

  const navigate = useNavigate();
 
  const [categories, setCategories] = useState([]);
  useEffect(()=>{
    categoryApi.get().then(res=>setCategories(res.data.data));
  }, [])


  return (
    <>
    
    <Hero />

    <OurBarbers />
   
    <AboutUs />

    <ContactForm />
   
 <div style={{backgroundColor:'#fcf9f5', height:'80vh', backgroundSize:'cover'}}>
        <div className="container h-100 my-10" >

          <div className="row justify-content-center align-items-center h-100 text-white">

           <div className="col-12 text-center text-black">
                           <h5>Servicios</h5>
               <h2 className="text-primary">Conoce qué puede ofrecerte Corte</h2>  
               <hr/>            
          </div>

              {categories?.map(category=>( 

                <div className="col-10 col-md-4 text-center text-black">
                
                    <Card  onClick={()=>navigate('/servicios?category_id='+category.id)} className="card-category">
                      
                      <img alt="" width="100px" src={category.image} />

                      <h3>{category.name}</h3>

                      <p>{category.description}</p>

                    </Card>

            
                 </div>
            ))}
           
            </div>   

     </div>
   
    </div>
   

    </>
  );
}

export default App;
