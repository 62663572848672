/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal';
import Builder from '../../../components/forms';
import { saveUserAsync } from '../../../redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getRolesAsync} from '../../../redux/role/actions'
import {Button} from 'reactstrap';

export const Create = () => {

 
  const dispatch = useDispatch();
  const [fields,setFields]= useState([]);
  const { roles } = useSelector((state) => state.role)
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(()=>{

    if(roles.length){

      setFields([
      {
        name:'name',
        label:'Nombre',
        defaultValue:'',
        required:true
      },
      {
        name:'lastname',
        label:'Apellido(s)',
        defaultValue:'',
        required:true
      },      
      {
        name:'email',
        label:'Email',
        defaultValue:'',
        required:true
      },      
      {
        name:'role_id',
        label:'Rol',
        defaultValue:roles[0].id,
        required:true,
        options: roles.map(role=><option key={role.id} value={role.id}>{role.name}</option>)
      },

      ])

    }

  },[roles]);

 
  useEffect(()=>{
    roles?.length===0 && dispatch(getRolesAsync());
  }, [roles, dispatch])

  const saveUser = (data)=>{
    dispatch(saveUserAsync(data));
  }

    return (
     <>
       <Button onClick={()=>setModalOpen(true)}> Crear usuario</Button>
       <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={'Crear usuario'}


        >
        <Builder fields={fields}   message='Crear usuario' onClick={saveUser} />
         
       </Modal>
        
     </>
    )
  
}