import { Button } from "reactstrap"
import React, {useState} from "react"
import ConfirmDialog from '../modal/ConfirmDialog';

const Confirm=({style, title='Confirmar' , confirm=true, icon=false, message='Confirma tu accion', onClick, text='Confirma'})=> {
    

   

    const handleClick = ()=>{
     
      if(confirm) {
        setModalOpen(true);        
      } else {
        onClick();
      }
    }


    const [modalOpen, setModalOpen] = useState(false);
    return (
      <>
        <Button style={style || {margin:'1rem 0rem', 'backgroundColor':"#9E8A78","color":"white"}} onClick={handleClick}>

        {
          icon ?  (<i className={icon} />) :

            (<>{text}</>)
        }
        </Button>
         <ConfirmDialog

            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            message={message}
            onClick={onClick}
          />

      </>
    )
  }

export default Confirm