/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal';
import Builder from '../../../components/forms';
import { updateCategoryAsync } from '../../../redux/category/actions';
import { useDispatch } from 'react-redux';
import {FaPen} from 'react-icons/fa6';

export const Edit = ({category}) => {

 
  const dispatch = useDispatch();
  const [fields,setFields]= useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(()=>{


      setFields([
      {
        name:'name',
        label:'Nombre',
        defaultValue:category.name,
        required:true
      },
        {
        name:'description',
        label:'Descripcion',
        required:true,
        defaultValue:category.description,
        textarea:true
      },


      ])


  },[category]);


  const updateCategory = (data)=>{
    dispatch(updateCategoryAsync(category.id, data));
  }

    return (
     <> 
     <FaPen style={{color:'#58A9B8'}}  onClick={()=>setModalOpen(true)} />
       <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={'Editar categoria'}


        >
        <Builder fields={fields}   message='Editar categoria' onClick={updateCategory} />
         
       </Modal>
        
     </>
    )
  
}