import Builder from '../../components/forms';
import {Card} from 'reactstrap';
import {authApi} from '../../api';
import {useNavigate, useParams} from 'react-router-dom';

export default function ChangePassword()
{

	const navigate = useNavigate();
	const {token} = useParams();

	console.log(token)



	const fields = [
	{
		name:'password',
		password:true,
		label:'Password',
		required:true,
	},
	{
		name:'password_confirmation',
		password:true,
		label:'Confirma tu Password',
		required:true,
	},

	];

	const onClick=(data)=>{
		data.token = token;
		authApi.changePassword(data).then(()=>{
			navigate('/login')
		})
	}



	return <div className="row  h-100 justify-content-center align-items-center">
	<div className="col-md-6">


	<Card className="p-3 shadow">

	 <h5 className="text-center card-title">Cambiar Password</h5>

	<Builder text="Registrarse" fields={fields} confirm={false} onClick={onClick}/>
	<span className="text-center cursor-pointer" onClick={()=>navigate('/recuperar-password')}>¿Olvidaste tu contraseña?</span>
	<span className="text-center cursor-pointer fw-bold" onClick={()=>navigate('/registro')}>Crear cuenta</span>

	</Card>

	</div>
	</div>
	
}