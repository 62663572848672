import React, {useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

import {useNavigate} from 'react-router-dom';
import { useDispatch} from 'react-redux'
import {logout} from '../../redux/auth/actions';
import useAuth from '../../hooks/useAuth';
import logo from '../../assets/logo.png';

export default function NavBar(props) {
 
   
  const {user, Iam} = useAuth();

  const dispatch = useDispatch();
  const  navigate = useNavigate();
  const [isOpen, setIsOpen ] =useState(false);
  const toggle=()=> {
    setIsOpen(!isOpen)
  }

  const goTo  = (route)=>{
    navigate(route);
  }

  const closeSession = ()=>{
    dispatch(logout());
  }

    return (
        <Navbar  color="dark" dark  expand="md">
          <NavbarBrand href="/"> <img alt=''  width="80px" style={{filter:'invert(1)', marginRight:'10px'}} src={logo} /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse className="justify-content-end" isOpen={isOpen} navbar>
            <Nav className="ml-auto text-white mr-2" navbar>
            

            {Iam('cliente') && (<NavItem>
                <NavLink onClick={()=>goTo('/citas')}>Citas</NavLink>
              </NavItem>)}
              
              <NavItem>
                <NavLink href="/servicios">Servicios</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/profesionales">Profesionales</NavLink>
              </NavItem>

              {!user && (<NavItem>
                <NavLink onClick={()=>goTo('/login')}>Iniciar Sesion</NavLink>
              </NavItem>)}
              




              {user?.id && (<UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Mi cuenta
                </DropdownToggle>
                <DropdownMenu end>
                  {user?.role?.name !=='cliente' && 
                  (<DropdownItem onClick={()=>goTo('/admin/citas')}>
                    Panel de control
                  </DropdownItem>)}
                  <DropdownItem onClick={()=>navigate('/perfil')}>
                    Ir a mi perfil
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={closeSession}>
                    Cerrar sesion
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>)}
            </Nav>
          </Collapse>
        </Navbar>
    );
}