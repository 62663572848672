import axios, {authAxios, authAxiosMedia} from './../config/axios';

class User{
    get=(params=[])=>{
        return authAxios.get('/users',{
            params
        });
    }


    barbers=(params=[])=>{
        return axios.get('/barbers',{
            params
        });
    }


    randomBarbers=(params=[])=>{
        return axios.get('/barbers/random');
    }

        showBarber=(id)=>{
        return axios.get('/barbers/'+id);
    }   

    show=(id)=>{
        return authAxios.get('/users/'+id);
    }   

    save=(data)=>{
        return authAxios.post('/users',data);
    }

    update=(id,data)=>{
        return authAxios.put(`/users/${id}`,data);
    }   

    updateMine=(_id, data)=>{
        return authAxios.put(`/users/my-user`,data);
    }  

    ban=(id)=>{
        return authAxios.post(`/users/${id}/ban`);
    }   

    unban=(id)=>{
        return authAxios.post(`/users/${id}/unban`);
    }

    unlock=(id)=>{
        return authAxios.post(`/users/${id}/unlock`);
    }       
    
    delete=(id)=>{
        return authAxios.post(`/users-delete/delete/${id}`);
    }   
    

    //trash
    trash=(params=[])=>{
        return authAxios.get('/users/trash',{
            params
        });
    }

        //trash
    unDelete=(id)=>{
        return authAxios.post(`/users/${id}/unDelete`);
    }

    changeImage=(id, image)=>{
        let formData = new FormData();
        formData.append('image', image);
        return authAxiosMedia.post(`/users/${id}/image`,formData);
    }


    changeMyImage=(_id, image)=>{
        let formData = new FormData();
        formData.append('image', image);
        return authAxiosMedia.post(`/users/my-image`,formData);
    }


    resendVerification=()=>{
        return authAxios.get(`/users/verification`);
    }

}

const user = (new User());
export default user;

