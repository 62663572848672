import Builder from '../../components/forms';
import {Card} from 'reactstrap';
import {authApi} from '../../api';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

export default function Login()
{

	const navigate = useNavigate();


	const fields = [
	{
		name:'email',
		type:'email',
		label:'Email',
		required:true,
	}
	];

	const onClick=(data)=>{
		authApi.recoverPassword(data).then(()=>{
			toast.success('Enlace enviado revisa tu correo')
		})
	}



	return <div className="row  h-100 justify-content-center align-items-center">

	<div className="col-md-6">

	<Card className="p-3 shadow">

	 <h5 className="text-center card-title">Recuperar contraseña</h5>

	<Builder text="Enviar enlace"  fields={fields} confirm={false} onClick={onClick}/>
	<span className="text-center cursor-pointer" onClick={()=>navigate('/login')}>Iniciar sesión</span>
	<span className="text-center cursor-pointer fw-bold" onClick={()=>navigate('/registro')}>Crear cuenta</span>

	</Card>

	</div>
	</div>
	
}