import React, {useEffect, useState} from 'react';
import {useNavigate,  useLocation} from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

export default function Navbar ({routes}) {

  const  {pathname} = useLocation();
  const navigate = useNavigate();
  const [index,setIndex] = useState(1);

  useEffect(()=>{

    routes.map((object,_index)=>{
      const {route} = object;
      if(route===pathname) {
        setIndex(_index)
      }
      return object;
    })
  }, [routes, pathname])

  const goTo = (route) =>{
    navigate(route);
  }

  const checkCurrent = (route)=>{
    
    if(strEndsWith(window.location.href, route)) {
      return 'nav-current'
    }
    return ''

  }

  const  strEndsWith =(str, suffix)=> {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;;
  }

  return (
    <>
    <Nav className="page-navbar" tabs style={{marginBottom:'1rem'}} variant='soft-rounded' index={index}   colorScheme='green'>
        <NavItem className="d-flex">
          {routes && routes.map(({name,route}, key)=>
            (<NavLink  className={checkCurrent(route)} key={key} onClick={()=>goTo(route)} >{name}</NavLink>)
          )}
        </NavItem>
      </Nav>

    </>
  )
}
