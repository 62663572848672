import axios,{authAxios, authAxiosMedia} from './../config/axios';

class Category{
    get=(params)=>{
        return axios.get('/categories', {
            params
        });
    }   

    save=(data)=>{
        return authAxiosMedia.post('/categories', data);
    }


    update=(id, data)=>{
        return authAxios.put(`/categories/${id}`, data);
    }

    updateImage=(id, data)=>{

        let formData = new FormData();
        formData.append('image', data['image'])
        return authAxiosMedia.post(`/categories/${id}/image`, formData);
    }
    delete=(id)=>{
        return authAxios.delete(`/categories/${id}`);
    }       
}

const category = (new Category());
export default category;