import { Button, Card } from "reactstrap";
import { FaStar } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "../styles.scss";

function App({ service }) {
  const getShortDescription = (description) => {
    if (description.length > 100) {
      return description.slice(0, 100) + "...";
    }
    return description;
  };

  const navigate = useNavigate();

  return (
    <div className="col-12 col-xl-9">
      <Card className="card-barber-service">
        <div className="row m-0">
          <div
            style={{
              backgroundImage: `url(${service.image})`,
            }}
            className="col-12 col-md-5 bg-image"
          ></div>
          <div className="col-12 col-md-7 p-0 position-relative">
            <div className="card-body p-4">
              <h4>{service.name}</h4>
              <p className="stars">
                <FaStar />{" "}
                {service.stars ? service.stars + "/ 5" : "Sin reviews"}{" "}
              </p>

              <div className="description">
                {" "}
                {getShortDescription(service.description)}
              </div>
            </div>

            <div className="card-barber-footer d-flex">
              <div className="col-6 p-3">
                <Button
                  style={{ minWidth: "180px" }}
                  color="primary"
                  onClick={() => navigate("/servicios/" + service.id)}
                >
                  Ver Tarifas{" "}
                </Button>
              </div>
              <div
                className="col-6 row align-items-center justify-content-center cursor-pointer"
                onClick={() => navigate("/profesionales/" + service.user_id)}
              >
                <span className="p-1 d-block w-auto fw-bold">
                  {service.barber?.fullName}
                </span>

                <div
                  style={{
                    padding: 0,
                    borderRadius: "1000px",
                    width: "60px",
                    height: "60px",
                    overflow: "hidden",
                  }}
                >
                  <img alt="" width="60" src={service?.barber?.image} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default App;
