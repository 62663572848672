import Create from '../../admin/appointments';
function App() {


  return (
    <>
      <Create mode="web" containerClass="container" />
  
    </>
  );
}

export default App;
