/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal';
import Builder from '../../../components/forms';
import { updateCategoryImageAsync } from '../../../redux/category/actions';
import { useDispatch } from 'react-redux';
import {FaFileImage} from 'react-icons/fa6';

export const EditImage = ({category}) => {

 
  const dispatch = useDispatch();
  const [fields,setFields]= useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(()=>{


      setFields([
      {
        name:'image',
        label:'Imagen',
        required:true,
        file:true
      }

      ])


  },[category]);


  const updateCategory = (data)=>{
    dispatch(updateCategoryImageAsync(category.id, data));
  }

    return (
     <> 
     <FaFileImage style={{color:'#58A9B8'}}  onClick={()=>setModalOpen(true)} />
       <Modal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          title={'Editar categoria'}


        >

        <img alt="" src={category.image} />
        <Builder fields={fields}   message='Editar Imagen' onClick={updateCategory} />
         
       </Modal>
        
     </>
    )
  
}