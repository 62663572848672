import React from "react"
import {Link} from 'react-router-dom'
import {FaEye} from 'react-icons/fa6';

const Show=({route})=> {
    

    return (
      <>
      <Link to={route}> <FaEye style={{color:'#fc6452d9'}} /></Link>
      </>
    )
  }

export default Show;