import React from "react"
import {Link} from 'react-router-dom'
import {FaPen} from 'react-icons/fa6';

const Edit=({route})=> {
    

    return (
      <>
      <Link to={route}> <FaPen style={{color:'#58A9B8'}} /></Link>
      </>
    )
  }

export default Edit;