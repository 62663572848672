import {  createReducer } from "@reduxjs/toolkit";
import types from './actionTypes';

const initialState = {
    appointments:[],
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(types.setAppointments, (state, action) => {
      state.appointments = action.payload;
    }) 
});