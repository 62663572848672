import Address from '../../admin/users/Show/Address';

function Profile() {


  return (
    <div style={{minHeight:'50vh'}}>
    


      <Address   mode="profile" containerClass="container"/>
     
    </div>
  );
}

export default Profile;
