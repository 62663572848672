import Builder from "../../components/forms";
import { Card } from "reactstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";

export default function Login() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fields = [
    {
      name: "email",
      type: "email",
      label: "Email",
      required: true,
    },
    {
      name: "password",
      password: true,
      label: "Password",
      required: true,
    },
  ];

  const onClick = (data) => {
    dispatch(login(data));
  };

  return (
    <div className="row h-100 justify-content-center align-items-center">
      <div className="col-md-6">
        <Card className="p-3 shadow">
          <h5 className="text-center card-title">Login</h5>

          <Builder
            text="Iniciar sesión"
            fields={fields}
            confirm={false}
            onClick={onClick}
          />
          <span
            className="text-center cursor-pointer"
            onClick={() => navigate("/recuperar-password")}
          >
            ¿Olvidaste tu contraseña?
          </span>
          <span
            className="text-center cursor-pointer fw-bold"
            onClick={() => navigate("/registro")}
          >
            Crear cuenta
          </span>
        </Card>
      </div>
    </div>
  );
}
