import { useEffect, useState } from "react";
import { serviceApi, feeApi } from "../../../api";
import "../styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import CardBarber from "../Professionals/CardBarber";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";

function App() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [service, setService] = useState({});
  const [fees, setFees] = useState([]);
  useEffect(() => {
    serviceApi
      .show(id)
      .then((res) => {
        setService(res.data.data);

        feeApi
          .get({
            active: 1,
            service_id: res.data.data.id,
          })
          .then((res2) => {
            setFees(res2.data.data);
          });
      })
      .catch((_err) => {
        navigate("/404");
      });
  }, [id, navigate]);

  const appointment = (fee) => {
    let route = `/citas/nueva`;

    localStorage.setItem("fee", fee.id);
    localStorage.setItem("service", fee.service_id);
    localStorage.setItem("barber", service.user_id);

    if (user?.id) {
      navigate(route);
    } else {
      localStorage.setItem("next-route", route);
      navigate("/login");
    }
  };

  return (
    <>
      <div style={{ maxWidth: 1280 }} className="container my-10">
        <div className="row">
          <div className="col-lg-3">
            <CardBarber description={false} barber={service?.barber || {}} />
          </div>
          <div className="col-lg-9 p-5">
            <h1>{service.name}</h1>
            <div>{service.description}</div>

            {fees.map((fee) => (
              <div key={fee.id} className="row  text-center m-3 bg-light">
                <div
                  className="col-12 col-lg-4 p-3"
                  style={{ background: "#fbe4e4" }}
                >
                  <p className="h3"> {`$${fee.price}`}</p>
                  <p > {`${fee.minutes}`} minutos</p>

                  <Button onClick={() => appointment(fee)} color="primary">
                    Agenda tu cita
                  </Button>
                </div>

                <div className="col-12 col-lg-8 p-3">
                  <h4>{fee.title}</h4>
                  <div>{fee.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
