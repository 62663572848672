import '../styles.scss';
import {useNavigate} from 'react-router-dom'
function App({barber}) {


  const navigate = useNavigate();
  
  return (
        <div  key={barber.id} className="col-10 col-sm-6 mb-4 col-md-4 col-lg-3">
        <div className="card-barber" onClick={()=>navigate('/profesionales/'+barber.id)}>


          <div  style={{
              backgroundImage: `url(${barber.image})`
            }}  className="image bg-image">
            </div>

          <div className="card-barber-footer">
            
              <h4>{barber.fullName}</h4>

          </div>

        </div>
        </div>

      )

}

export default App;
