import {authAxios} from './../config/axios';

class Message{
    chats=(params)=>{
        return authAxios.get('/chats', {
            params
        });
    }   


    save=(chat_id, message)=>{
        return authAxios.post('/messages', {
            chat_id,
            message
        });
    }   

    myChats=()=>{
        return authAxios.get('/chats/my-chats');
    }   

    myMessages=(params)=>{
        return authAxios.get('/messages/my-messages', {
            params
        });
    }  
    makeChat=(barber_id)=>{
        return authAxios.post('/chats/make/'+barber_id);
    }
     
}


const message = (new Message());
export default message;