import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { serviceApi, feeApi } from "../../../../api";
import { Card, CardBody, CardTitle } from "reactstrap";
import Navbar from "./navbar";
import { CreateFee } from "./CreateFee";
import { EditFee } from "./EditFee";
import CardFee from "./CardFee";

export default function Show() {
  const [service, setService] = useState(false);
  const [feeEdit, setFeeEdit] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [fees, setFees] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    serviceApi.show(id).then((res) => {
      setService(res.data.data);

      feeApi
        .get({
          service_id: res.data.data.id,
        })
        .then((res2) => {
          setFees(res2.data.data);
        });
    });
  }, [id]);

  const saveService = (data) => {
    data.service_id = service.id;
    feeApi.save(data).then((res) => {
      setFees([...fees, res.data.data]);
    });
  };

  const deleteFee = async (fee) => {
    await feeApi.delete(fee.id);
    setFees(fees.filter((_fee) => _fee.id !== fee.id));
  };

  const editFee = (fee) => {
    setModalOpen(true);
    setFeeEdit(fee);
  };

  const onEdit = (data) => {
    feeApi.update(feeEdit.id, data).then((res) => {
      setFees(
        fees.map((fee) => {
          if (fee.id === feeEdit.id) {
            return res.data.data;
          }
          return fee;
        })
      );
    });
    setModalOpen(false);
  };
  return (
    <div className="row">
      <Navbar />

      <div className="col-md-3">
        <Card
          style={{
            width: "18rem",
          }}
        >
          <img
            alt="Sample"
            src={service.image || "https://picsum.photos/300/200"}
          />
        </Card>
      </div>

      <div className="col-md-9">
        <Card className="pb-5">
          <CardBody>
            <CardTitle tag="h1">{service.name}</CardTitle>
            <CardTitle tag="h5">Descripcion</CardTitle>

            <div> {service.description}</div>
          </CardBody>
        </Card>

        <Card className="pb-5">
          <CreateFee saveService={saveService} />
          <EditFee
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            onSave={onEdit}
            fee={feeEdit}
          />

          {fees.map((fee) => (
            <CardFee
              fee={fee}
              onEdit={() => editFee(fee)}
              onDelete={() => deleteFee(fee)}
            />
          ))}
        </Card>
      </div>
    </div>
  );
}
