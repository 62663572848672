/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input, Button } from "reactstrap";
import { addressApi } from "../../../../api";
import useAuth from "../../../../hooks/useAuth";

export default function UserIndex({ data, setData, next }) {
  const [addresses, setAddresses] = useState([]);

  const { user, Iam } = useAuth();
  useEffect(() => {
    if (!user?.id) return;
    let newData = {
      ...data,
    };

    if (Iam("cliente")) {
      newData.client = user;
    }

    setData(newData);

    data?.client?.id &&
      addressApi
        .get({
          user_id: data?.client.id,
        })
        .then((res) => setAddresses(res.data.data));
  }, [user]);

  const onChange = (e) => {
    const addressfound = addresses.filter(
      (address) => address.id === e.target.value
    )[0];

    const { address, city, cp, state, lat, lng } = addressfound;

    const fullAddress = `${address}, ${city}, ${cp} ${state}`;

    // Crear el nuevo objeto con address, lat y lng
    const newAddress = {
      address: fullAddress.trim(),
      lat,
      lng,
    };

    setData({
      ...data,
      address: newAddress,
    });

    next();
  };

  return (
    <>
      <h3 className="mb-4">Selecciona una direccion</h3>

      <Input type="select" onChange={onChange}>
        <option disabled selected>
          --Selecciona una direccion--
        </option>
        {addresses.map((address) => (
          <option value={address.id}>
            {address.address} {address.city} | {address.state} {address.cp}{" "}
          </option>
        ))}
      </Input>
      <Button className="mt-3" onClick={next}>
        Continuar sin direccion
      </Button>
    </>
  );
}
