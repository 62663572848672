import {useNavigate} from 'react-router-dom';

function App() {

  const navigate = useNavigate();
  return (
    <div className="row mt-5 h-100 justify-content-center align-items-center">
      <span style={{fontSize:60}}className="text-center">404</span>
      <span style={{fontSize:20}}className="text-center">Pagina no encontrada</span>
      <span style={{fontSize:20}}className="text-center">
        <span className="cursor-pointer" onClick={()=>navigate('/')} >Ir al inicio</span>
      </span>
    </div>
  );
}

export default App;
