/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal";
import Builder from "../../../../components/forms";
import useAuth from "../../../../hooks/useAuth";

export const EditFee = ({ fee, onSave, setModalOpen, modalOpen = false }) => {
  const [fields, setFields] = useState([]);
  const { Iam, user } = useAuth();
  useEffect(() => {
    if (!user?.id) return;

    let extra = [];

    if (Iam("administracion")) {
      extra.push({
        name: "active",
        label: "Activo",
        boolean: true,
      });
    }

    if (fee?.id) {
      setFields([
        {
          name: "price",
          label: "Precio",
          defaultValue: fee.price,
          required: true,
          type: "number",
        },
        {
          name: "title",
          label: "Titulo",
          defaultValue: fee.title,
          required: true,
        },
        {
          name: "minutes",
          label: "Minutos de duración",
          defaultValue: fee.minutes,
          options: Array.from({ length: 24 }, (_, index) => (index + 1) * 5).map(
          (option) => (
            <option key={option} selected={option==fee.minutes?true : false} value={option}>
              {option}
            </option>
          )
        ),
          required: true,
        },
        ...extra,
        {
          name: "description",
          label: "Descripcion",
          required: true,
          textarea: true,
          defaultValue: fee.description,
        },
      ]);
    }
  }, [user, fee]);

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={"Editar tarifa"}
      >
        <Builder
          fields={fields}
          message="Editar Tarifa"
          onClick={(res) => {
            onSave(res);
            setModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
