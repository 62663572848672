/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {appointmentApi} from '../../../api';
import Builder from '../../../components/forms';
import {Card, CardBody, Input, FormGroup, Label} from 'reactstrap';
import {DatePicker} from 'reactstrap-date-picker'
import {toast} from 'react-toastify'
import SelectUser from '../../../components/select/user'
import SelectAddress from '../../../components/select/address'

export default function UserIndex({containerClass='container-fluid mt-3'}) {
  


    const {id} = useParams();
    
    const [appointment, setAppointment] = useState({});
    const [fields, setFields] = useState([]);
    const [data, setData] = useState({});



    useEffect(()=>{
        appointmentApi.show(id).then(res=>{
          setAppointment(res.data.data);
          setData({
            date: res.data.data.date,
          })

        })
    },[id])


    useEffect(()=>{

      const statuses = [
        'pendiente',
        'aceptada',
        'rechazada',
        'cancelada',
        'terminada',
        'perdida',
      ];

     
      appointment.id && setFields([
     
      {
        name:'time',
        label: 'Hora', 
        defaultValue: appointment.time,
        type:'time'
   
      },
      {
        name:'minutes',
        label: 'Minutos', 
        defaultValue: appointment.minutes,
        options: [15,30,45,60,90].map((minute,index)=> <option key={index} value={minute}>{minute} </option>)

      },
      {
        name:'status',
        label: 'Status', 
        defaultValue: appointment.status,
        options: statuses.map((status,index)=> <option key={index} value={status}>{status} </option>)

      },
      {
        name:'description',
        label: 'Descripcion', 
        defaultValue: appointment.description,
        textarea:true,
      }

      ])

    },[appointment])


    const submit= (_data) =>{
      _data.time = _data.time.slice(0,5);
      _data = {
        ..._data,
        ...data
      }


      appointmentApi.update(id, _data).then(res=>{
        setAppointment(appointment)
        toast.success("cita actualizada corerctamente")
      })

    }


    const onChangeDate= (_data, f) =>{
      setData({
        ...data,
        date: f.split("/").reverse().join("-")
      })
    }





    return (

      <div className="row">
       <div className="col-12">

        <Card>
          
          <CardBody>

            <h3>Editar Cita</h3>



            <span className="mb-1 mt-1 d-block">Fecha</span>
            <DatePicker 

              defaultValue={appointment.date}
              onChange= {onChangeDate} />

            <Builder fields={fields} onClick={submit} />
            
          </CardBody>
        </Card>
         
       </div>
      </div>


    )
  
}