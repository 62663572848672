/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  getServicesAsync,
  deleteServiceAsync,
} from "../../../redux/service/actions";
import DataTables from "../../../components/datatable";
import { Edit, Show, Delete } from "../../../components/buttons";
import { Card } from "reactstrap";
import { Create } from "./Create";
import useAuth from "../../../hooks/useAuth";
import { act } from "react";

export default function UserIndex() {
  const selectable = (state) => state.service.services;

  const { AdminOrMe } = useAuth();

  const deleteDispatch = (row_id) => {
    return deleteServiceAsync(row_id);
  };

  const columns = (row) => {
    return {
      activo: row.active ? "Si" : "No",
      nombre: row?.name,
      categoria: row.category?.name,
      barbero: row?.barber?.fullName,
      opciones: (
        <div>
          <Show route={`/admin/servicios/${row.id}`} />
          <Edit route={`/admin/servicios/editar/${row.id}`} />
          <Delete id={row.id} dispatchable={deleteDispatch} />
        </div>
      ),
    };
  };

  const dispatchable = (query) => {
    return getServicesAsync({
      ...query,
      with: "category|barber",
    });
  };

  return (
    <Card>
      <Create />
      <DataTables
        columns={columns}
        dispatchable={dispatchable}
        selectable={selectable}
        title="Gestion de servicios"
      />
    </Card>
  );
}
