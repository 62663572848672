import UserIndex from '../pages/admin/users';
import UserShow from '../pages/admin/users/Show';
import UserAddress from '../pages/admin/users/Show/Address';
import AdminLayout from '../layouts/Admin';

import CategoryIndex from '../pages/admin/categories';

import ServiceIndex from '../pages/admin/services';
import ServiceShow from '../pages/admin/services/Show';
import ServiceEdit from '../pages/admin/services/Edit';


import AppointmentIndex from '../pages/admin/appointments';
import AppointmentHistory from '../pages/admin/appointments/History';
import AppointmentCreate from '../pages/admin/appointments/Create';
import AppointmentShow from '../pages/admin/appointments/Show';
import AppointmentEdit from '../pages/admin/appointments/Edit';



import ConfigIndex from '../pages/admin/config/index';

export const admin=[    
    { path:'/usuarios', Component:UserIndex},
    { path:'/usuarios/:id', Component:UserShow},
    { path:'/usuarios/direcciones/:id', Component:UserAddress},

    { path:'/categorias/:id?', Component:CategoryIndex},


    { path:'/servicios', Component:ServiceIndex , role:['barbero']},
    { path:'/servicios/:id', Component:ServiceShow, role:['barbero']},
  
    { path:'/servicios/editar/:id', Component:ServiceEdit, role:['barbero']},

    { path:'/citas', Component:AppointmentIndex, role:['barbero']},
    { path:'/citas/:id', Component:AppointmentShow, role:['barbero']},
    { path:'/citas/editar/:id', Component:AppointmentEdit, role:['barbero']},
    { path:'/citas/historial', Component:AppointmentHistory, role:['barbero']},
    { path:'/citas/nueva', Component:AppointmentCreate, role:['barbero']},
    
    { path:'/citas/nueva', Component:AppointmentCreate, role:['barbero']},

    { path:'/configuracion', Component:ConfigIndex, role:['barbero']},

].map(route=>{

    if(!route.role) {
        route.role= ['administracion'];
    } else {
        route.role.push('administracion')
    }

    route.path = 'admin'+route.path

    route.Layout = AdminLayout;
    return route;

});



