import axios, {authAxios, authAxiosMedia} from './../config/axios';

class Service{
    get=(params)=>{
        return axios.get('/services', {
            params
        });
    }   

    show=(id)=>{
        return axios.get(`/services/${id}`);
    }   


    save=(data)=>{
        let formData = new FormData();
        for (let k in data) {
            formData.append(k, data[k]);
        }

        return authAxiosMedia.post('/services', data);
    }


    changeImage=(id, image)=>{
        let formData = new FormData();
        formData.append('image', image);
        return authAxiosMedia.post(`/services/${id}/image`,formData);
    }


    update=(id, data)=>{
        return authAxios.put(`/services/${id}`, data);
    }

    delete=(id)=>{
        return authAxios.delete(`/services/${id}`);
    }       
}

const service = new Service();
export default service;