import  { FaWhatsapp, FaStar} from 'react-icons/fa6';
import '../styles.scss';

function App({barber, long=true, description=true}) {


  return (
          <div className="card-barber-info">
            <img  style={{maxHeight:200, display:'block', margin:'0 auto'}} alt="" className="img-fluid" src={barber?.image} />

            <div className="py-5">
            <h2 className="text-center">{barber?.fullName}</h2>
            <p className="text-center stars"><FaStar /> {barber?.stars ?  barber.stars + '/ 5' : 'Sin reviews'} </p>

           
              {long && description && (<p>{barber?.about}</p>)}
              
              {long && <table style={{width:'100%'}}>
                  <tr>
                    <td>Telefono: </td>
                    <td className="fw-bold">{barber?.phone}</td>
                  </tr>

                </table>}

                {long && barber?.phone_area && 
                <a className="btn btn-whatsapp" href={`https://wa.me/${barber.phone_area}${barber.phone}`} >
                  <FaWhatsapp />Whatsapp
                </a>}
              
          </div>
          </div>
  );
}

export default App;
