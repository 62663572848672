import Login from "../pages/guest/Login"
import RecoverPassword from "../pages/guest/RecoverPassword"
import Register from "../pages/guest/Register"
import ChangePassword from "../pages/guest/ChangePassword"
import GuestLayout from '../layouts/Guest';

export const guest=[
        { path:'/login', Component:Login},
        { path:'/recuperar-password', Component:RecoverPassword},
        { path:'/registro', Component:Register},
        { path:'/cambiar-password/:token', Component:ChangePassword},   
].map(route=>{
    route.Layout= GuestLayout;
    route.role=false;
    return route;
})
