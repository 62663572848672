/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Confirm } from "../../../../components/buttons";
import { Button } from "reactstrap";

export default function CardFEeComponent({
  fee,
  confirm = false,
  onClick,
  onDelete,
  onEdit,
  text,
}) {
  return (
    <div className="row p-3 m-3 bg-ligh text-center text-lg-start">
      <div className="col-lg-3 mb-3">
        <p className="h3"> {`$${fee.price}`}</p>
        <p> {`${fee.minutes} minutos`}</p>
        {confirm && text && onClick(<Confirm text={text} onClick={onClick} />)}

        {text && onClick && (
          <Button color="danger" size="sm" onClick={onClick}>
            {text}
          </Button>
        )}
        {onDelete && (
          <Button color="danger" size="sm" onClick={onDelete}>
            Eliminar
          </Button>
        )}
        {onEdit && (
          <Button color="primary" size="sm" onClick={onEdit}>
            Editar
          </Button>
        )}
      </div>

      <div className="col-lg-9">
        <h3>
          {fee.title} {fee.active ? "" : "(inactivo)"}
        </h3>

        <div> {fee.description}</div>
      </div>
    </div>
  );
}
