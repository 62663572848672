import React from 'react';
import './footer.styles.scss';
//import {FaFacebook, FaInstagram, FaWhatsapp} from 'react-icons/fa6';

export default function App() {
  return (
    <div className='footer text-center text-lg-start text-muted'>
{/*      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Siguenos en nuestras redes sociales</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <FaFacebook color='secondary' fab  />
          </a>          
          <a href='' className='me-4 text-reset'>
            <FaInstagram color='secondary' fab  />
          </a>
          <a href='' className='me-4 text-reset'>
            <FaWhatsapp color='secondary' fab  />
          </a>

        </div>
      </section>*/}
{/*
      <section className=''>
        <div className='container text-center text-md-start mt-5'>
          <div className='row mt-3'>
            <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <span color='secondary' icon='gem' className='me-3' />
                Company name
              </h6>
              <p>
                Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit
                amet, consectetur adipisicing elit.
              </p>
            </div>

            <div  className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Angular
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  React
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Vue
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Laravel
                </a>
              </p>
            </div>

            <div  className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='#!' className='text-reset'>
                  Pricing
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Settings
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Orders
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                  Help
                </a>
              </p>
            </div>

            <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <span color='secondary' icon='home' className='me-2' />
                New York, NY 10012, US
              </p>
              <p>
                <span color='secondary' icon='envelope' className='me-3' />
                info@example.com
              </p>
              <p>
                <span color='secondary' icon='phone' className='me-3' /> + 01 234 567 88
              </p>
              <p>
                <span color='secondary' icon='print' className='me-3' /> + 01 234 567 89
              </p>
            </div>
          </div>
        </div>
      </section>
*/}
      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        © {new Date().getFullYear()} Copyright: 
        <a className='text-reset fw-bold mx-2' href='/'>
          Corte
        </a>
      </div>
    </div>
  );
}