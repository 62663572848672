/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { feeApi, userApi } from "../../../../api";
import { Card, CardBody } from "reactstrap";

export default function SelectedServices({ data, setData }) {
  useEffect(() => {
    let fee_id = localStorage.getItem("fee") || false;
    let barber_id = localStorage.getItem("barber") || false;

    const getData = async () => {
      let newData = JSON.parse(JSON.stringify(data));

      if (barber_id) {
        try {
          const res = await userApi.barbers({
            id: barber_id,
          });
          newData.barber = res.data.data[0];
        } catch (e) {
          console.log(e);
        }
      }

      if (fee_id) {
        try {
          const res2 = await feeApi.get({
            with: "service",
            id: fee_id,
          });

          newData.fees = [res2.data.data[0]];
        } catch (e) {
          console.log(e);
        }
      }

      setData(newData);
    };

    getData();
  }, []);

  const clear = (text) => {
    return text.replaceAll(/(<([^>]+)>)/gi, " ");
  };

  const quitar = (fee, _index) => {
    let fee_id = localStorage.getItem("fee") || false;

    if (fee.id === fee_id) {
      localStorage.removeItem("fee");
      localStorage.removeItem("service");
    }
    setData({
      ...data,
      fees: data.fees.filter((_e, index) => index !== _index),
    });
  };
  return (
    <>
      <h6 className="my-4">Servicios seleccionados</h6>

      <Card>
        <CardBody>
          <br />
          {data &&
            data?.fees?.map((fee, index) => (
              <div key={index}>
                <p className="mb-0">
                  <b>Servicio</b> {fee.service.name}
                  {"   "}
                  <b>Tarifa</b> {clear(fee.title)}
                </p>
                <p className="mb-0">
                  <b>Precio</b> ${fee.price} {"   "} <b>Minutos</b> $
                  {fee.minutes}
                </p>
                <button
                  className="btn btn-sm btn-danger "
                  onClick={() => quitar(fee, index)}
                >
                  Quitar
                </button>
                <hr />
              </div>
            ))}
        </CardBody>
      </Card>
    </>
  );
}
