/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Button } from "reactstrap";
import Navbar from "../navbar";
import Steps from "../../../../components/steps";
import SelectDate from "./SelectDate";
import SelectService from "./SelectService";
import SelectedServices from "./SelectedServices";
import SelectTime from "./SelectTime";
import SelectAddress from "./SelectAddress";
import SelectAddressUser from "./SelectAddressUser";
import Resume from "./Resume";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CardBarber from "../../../web/Professionals/CardBarber";

export default function UserIndex({
  mode = "admin",
  containerClass = "container-fluid",
}) {
  const navigate = useNavigate();
  const [data, setData] = useState({
    fees: [],
    date: "",
    time: "",
    address: "",
    barber: {},
    client: {},
  });

  const [disableButton, setDisableButton] = useState(false);
  const [step, setStep] = useState(1);
  const { user, Iam } = useAuth();

  useEffect(() => {
    if (Iam("cliente") && !localStorage.getItem("barber")) {
      navigate("/profesionales");
    }

    if (Iam("barbero")) {
      localStorage.setItem("barber", user.id);
    }
  }, [user]);

  useEffect(() => {
    if (data[getKey()]) {
      if (data[getKey()].toString()?.trim() !== "") {
        setDisableButton(false);
        return;
      }
    }

    setDisableButton(true);
  }, [data, step]);

  const [steps] = useState(["Servicio", "Dia", "Hora", "Direccion", "Resumen"]);

  useEffect(() => {
    if (!user?.id) return;
    let newData = {
      ...data,
    };

    if (Iam("cliente")) {
      newData.client = user;
    }

    setData(newData);
  }, [user]);

  const getKey = () => {
    return Object.keys(data)[step - 1] ?? "";
  };
  const prev = () => {
    if (step === 1) return;

    setStep(step - 1);
  };

  const next = () => {
    if (step === steps.length) return;

    setStep(step + 1);
  };

  const renderBody = () => {
    const renders = [
      <SelectService data={data} setData={setData} next={next} />,
      <SelectDate data={data} setData={setData} next={next} />,
      <SelectTime data={data} setData={setData} next={next} />,
    ];

    renders.push(
      Iam("cliente") ? (
        <SelectAddressUser data={data} setData={setData} next={next} />
      ) : (
        <SelectAddress data={data} setData={setData} next={next} />
      )
    );

    renders.push(<Resume mode={mode} data={data} />);
    return renders[step - 1];
  };

  return (
    <>
      <Navbar mode={mode} />

      <div className={"container-fluid"}>
        <div className="row justify-content-center">
          <div className="col-lg-3">
            <CardBarber barber={data?.barber} long={false} />
          </div>
          <div className="col-lg-9">
            <Card>
              <CardBody>
                <Steps current={step} steps={steps} />

                {renderBody()}

                <div className="justify-content-end d-flex mt-5">
                  {step > 1 && (
                    <Button color="dark" onClick={prev}>
                      Anterior
                    </Button>
                  )}

                  {step < steps.length && (
                    <Button
                      color="primary"
                      disabled={disableButton}
                      onClick={next}
                    >
                      Siguiente
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>

            <SelectedServices data={data} setData={setData} />
          </div>
        </div>
      </div>
    </>
  );
}
