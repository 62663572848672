/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import { getCategoriesAsync, deleteCategoryAsync } from '../../../redux/category/actions'
import DataTables from '../../../components/datatable';
import {  Delete} from '../../../components/buttons';
import {Card} from 'reactstrap';
import { Create} from './Create';
import { Edit} from './Edit';
import { EditImage} from './EditImage';
import {useParams} from 'react-router-dom';

export default function UserIndex() {
  
  const {id} = useParams();
  const [fire, setFire]  = useState(1);
  useEffect(()=>{
    setFire(fire+1);
  }, [id]);

  const selectable = (state)=>(state.category.categories);


  const columns = (row)=>{
    return {
      _: <img alt="" width="60px" src={row.image}/>,
      nombre:row?.name,
      descripcion:row?.description || '-',
      opciones:(
        <div className="d-flex">
          <Edit category={row} />
          <EditImage category={row} />
          {row.categories?.length? null : <Delete  dispatchable={deleteCategoryAsync} id={row.id} route={`/admin/categorias/editar/${row.id}`} />}
        </div>
      )
    }
  } 

  const dispatchable=(query)=>{

    if(id) {
      return getCategoriesAsync({
        ...query,
        category_id:id,
      });      
    } else {
        return getCategoriesAsync({
        ...query,
        with:'categories:id,category_id',
        whereNull:'category_id'
      });
    }

  } 


    return (
      <Card >


        <Create id={id}/>
        <DataTables  
          columns={columns}
          dispatchable={dispatchable}
          fire={fire}
          selectable={selectable}
          title='Gestion de categoria'
        />
      


      </Card>
    )
  
}