import React, { useState, useEffect } from 'react'
import { useParams} from 'react-router-dom';
import { userApi } from '../../../../api';
import {toast} from 'react-toastify';
import Builder from '../../../../components/forms';
import { Card, CardBody} from 'reactstrap';
import InputFile from '../../../../components/forms/InputFile';
import {useSelector, useDispatch } from 'react-redux';
import { getRolesAsync} from '../../../../redux/role/actions'
import Navbar from './navbar';

export default function Edit({mode="admin", containerClass='container-fluid'}) {

    const [id, setId] = useState(false);
    const {id: paramId}  = useParams();
    const [user, setUser]  = useState({});
    const { roles } = useSelector((state) => state.role)
    const [fieldsUser, setFieldsUser] = useState([]);
    const {user: auth}  = useSelector((state)=>state.auth);

    useEffect(()=>{
      if(mode==='admin') {
        setId(paramId);
      }else {
        setId(auth.id)
      }

    },[auth, mode, paramId])

    const dispatch = useDispatch();
    useEffect(()=>{


      const extra = [];

      if(mode==="admin") {
         extra.push({
            name:'role_id',
            label:'Rol',
            defaultValue:user.role_id,
            required:true,
            options: roles.map(role=>(<option value={role.id}>{role.name}</option>))
        })
      }

        setFieldsUser([
        {
            name:'name',
            label:'Nombre',
            defaultValue:user.name,
        },            
        {
            name:'lastname',
            label:'Apellido',
            defaultValue:user.lastname,
        },        
        {
            name:'email',
            label:'Correo electronico',
            required:true,
            defaultValue:user.email,
        },
        {
            name:'password',
            label:'Cambiar Contraseña',
        },        
        {
            name:'about',
            label:'Acerca de',
            textarea:true,
            defaultValue:user.about,
        },
        {
            name:'phone',
            label:'Telefono',
            defaultValue:user.phone,
        },
        ...extra
        ])

    },[user, roles, mode]);





    useEffect(()=>{
        if(id) {           
            userApi.show(id).then(response=>{
                setUser(response.data.data);
            });

        }
    }, [id]);


  useEffect(()=>{
    mode==="admin" && roles?.length===0 && dispatch(getRolesAsync());
  }, [roles, dispatch, mode])

    const saveUser = (data)=>{
        if(data.password && data.password.trim()!==''){
            if(data.password.trim().length<8){
                toast.error('La Contraseña debe tenemos almenos 8 caracteres', {"theme":"dark"})
                return;
            }
        }


        let cb = null

        if(mode==="admin"){
          cb= userApi.update
        } else {
          cb =userApi.updateMine
        }

        cb(user.id,data).then(response=>{
          setUser(response.data.data);
          toast.success(response.data.msg, {"theme":"dark"});
        })

    }


    const changeImage = (e)=>{

        let  cb = null
        if(mode==="admin"){
            cb =userApi.changeImage
        }else {
            cb = userApi.changeMyImage;
        }

        cb(user.id, e.target.files[0]).then(res=>{
          setUser(res.data.data);
        })
    }

    return (
<>
       <Navbar  mode={mode}/>
         <div className={containerClass}>
        <div className="row">


            <div className="col-md-4">

            <Card>
              <CardBody>
                <p className="h2">Editar usuario</p>
                <Builder fields={fieldsUser} onClick={saveUser}/>

              </CardBody>
   
            </Card>
        </div>

           <div className="col-md-4">
         <Card>
              <CardBody>
                <img 
                    alt=""
                    className="img-fluid" 
                    style={{"margin":"3rem auto"}}
                    src={user?.image  || 'https://cdn-icons-png.flaticon.com/512/149/149071.png' }/>

       
                <InputFile accept={['image/png', 'image/gif', 'image/jpeg', 'image/webp']} onChange={changeImage}/>
       
              </CardBody>
   
            </Card>
            </div>

            
        </div>
        </div>
        </>


        )

}
