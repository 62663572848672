import Sidebar from './components/sidebar';
import Navbar from './components/AdminNavbar';

export default function Login({children})
{
	return <div className="App wrapper">
	<Sidebar />
	<div className="h-100 overflow-scroll" style={{width:'calc(100vw)'}}>
		<Navbar />
		
		<div  className="container-fluid ">
			{children}
		</div>
		</div>
	</div>


}