import auth from './auth/reducer'
import user from './user/reducer'
import role from './role/reducer'
import category from './category/reducer'
import service from './service/reducer'
import appointment from './appointment/reducer'
import {combineReducers} from '@reduxjs/toolkit';

const reducers = combineReducers({
    auth,
    user,
    role,
    category,
    service,
    appointment

});

export default reducers;
