
import {userApi, addressApi} from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';


const setUser = (user, response) => {
    if(user.id === response.data.data.id)
        return response.data.data;
    return user;
}

export const getUsersAsyc = (params) => async (dispatch) => {
    const response = await userApi.get(params)
    dispatch(types.setUsers(response.data.data));

}


export const banUserAsync = (id) =>async(dispatch,state)=>{
    const response = await userApi.ban(id);
    let users = JSON.parse(JSON.stringify(state().user.users));
    toast.success('Usuario baneado correctamente', {theme:'dark'})
    users.data = users.data.map(user=>setUser(user,response));

    dispatch(types.setUsers(users)); 

}

export const unbanUserAsync = (id) =>async(dispatch,state)=>{
    const response = await userApi.unban(id);
    let users = JSON.parse(JSON.stringify(state().user.users));
    toast.success('Usuario desbaneado correctamente', {theme:'dark'})
    users.data = users.data.map(user=>setUser(user,response));

    dispatch(types.setUsers(users)); 

}

export const unlockUserAsync = (id) =>async(dispatch,state)=>{
    const response = await userApi.unlock(id);
    let users = JSON.parse(JSON.stringify(state().user.users));
    toast.success('Usuario desbloqueado correctamente', {theme:'dark'})
    users.data = users.data.map(user=>setUser(user,response));

    dispatch(types.setUsers(users)); 

}



export const saveUserAsync = (data) => async (dispatch,state)=>{
    const response = await userApi.save(data);
    toast.success('Usuario guardado correctamente', {"theme":"dark"})
    let users = JSON.parse(JSON.stringify(state().user.users));

    users.data.push(response.data.data);
    dispatch(types.setUsers(users));

}





export const getAddressAsync = (params) => async (dispatch) => {
    const response = await addressApi.get(params)
    dispatch(types.setAddresses(response.data.data));
}


export const saveAddressAsync = (data) => async (dispatch,state)=>{
    const response = await addressApi.save(data);
    toast.success('Direccion guardada correctamente', {"theme":"dark"})
    let addresses = JSON.parse(JSON.stringify(state().user.addresses));

    addresses.push(response.data.data);
    dispatch(types.setAddresses(addresses));

}

export const deleteAddressAsync = (row_id) => async(dispatch, getState) => {
    await addressApi.delete(row_id);
    let currentState = JSON.parse(JSON.stringify(getState().user.addresses));

    currentState = currentState.filter(row=>row.id!==row_id);

    toast.success('Direccion eliminada correctamente', {'theme':"dark"});
    dispatch(types.setAddresses(currentState))
}
