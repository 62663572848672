import {authAxios} from './../config/axios';

class Address{
    get=(params={})=>{
        return authAxios.get('/addresses', {
            params
        });
    }   

    save=(data)=>{
        return authAxios.post('/addresses', data);
    }

    delete=(id)=>{
        return authAxios.delete(`/addresses/${id}`);
    }       
}

const address= (new Address());
export default address;