import jwt from "jwt-decode";

class Token {

	get = ()=>{
		return localStorage.getItem('token')
	}

	getPublic(){
		return  process.env.REACT_APP_JWT_SECRET;
	}

	json = ()=>{
		return this.decode(localStorage.getItem('token')||'');
	}
	decode = (token)=>{
		try{
		   return jwt(token);
		}
		catch(e){
			console.log(e, token)
			return false;
		}
	}

	set = (token)=>{
		return localStorage.setItem('token', token);
	}

	check = ()=>{
		let token = this.get();

		if(!token)
			return false;

		let response = this.decode(token);

		if(!response){
			this.destroy();
			return false;
		}

	    if (response.exp <= (new Date().getTime() ) / 1000) 
	    {
			this.destroy();
	    	return false;
	    }	

	    return response;

	}

	destroy = ()=>{
		return localStorage.removeItem('token')
	}


}

const token =  (new Token());
export default token;

