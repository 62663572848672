import React, {useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux'
import {logout} from '../../redux/auth/actions';
import logo from '../../assets/logo.png';

export default function NavBar(props) {
 
   
  const {user} = useSelector((state)=>state.auth) ;
  const dispatch = useDispatch();
  const  navigate = useNavigate();
  const [isOpen, setIsOpen ] =useState(false);
  const toggle=()=> {
    setIsOpen(!isOpen)
  }

  const goTo  = (route)=>{
    navigate(route);
  }
  console.log(user)

  const closeSession = ()=>{
    dispatch(logout());
  }

    return (
        <Navbar  color="dark" dark  expand="md">
          <NavbarBrand href="/"> <img alt=""  width="80px" style={{filter:'invert(1)', marginRight:'10px'}} src={logo} /></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse className="justify-content-end" isOpen={isOpen} navbar>
            <Nav className="ml-auto text-white mr-2" navbar>
             

          

              {user?.id && (<UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Mi cuenta
                </DropdownToggle>
                <DropdownMenu end>
                  {user.role.name ==='administracion' && 
                  (<DropdownItem onClick={()=>goTo('/admin/usuarios')}>
                    Panel de control
                  </DropdownItem>)}
                  <DropdownItem onClick={()=>navigate('/perfil')}>
                    Ir a mi perfil
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={closeSession}>
                    Cerrar sesion
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>)}
            </Nav>
          </Collapse>
        </Navbar>
    );
}