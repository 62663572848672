/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from 'react';
import {Input, Button} from 'reactstrap';
import {GoogleMap, LoadScript, Marker, Autocomplete} from '@react-google-maps/api';

const mapContainerStyle = {
  height: "400px",
  width: "100%"
};

const defaultCenter = {
  lat: 20.6767222,
  lng: -103.3474523
};

export default function SelectMap({onSelect, children}) {
  const [selectedPosition, setSelectedPosition] = useState(defaultCenter);
  const [currentPosition, setCurrentPosition] = useState(defaultCenter);
  const [addressInfo, setAddressInfo] = useState(null);
  const autocompleteRef = useRef(null);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setSelectedPosition({lat, lng});

  };


  useEffect(()=>{

    const {lat, lng} = selectedPosition;
    getFormattedAddress(lat, lng).then((formattedAddress)=>{

      const selectedAddress = {
        address: formattedAddress,
        lat,
        lng
      };

      setAddressInfo(selectedAddress);
      onSelect( selectedAddress);
    })
   


  }, [selectedPosition])

// Función para obtener la dirección formateada a partir de lat/lng
const getFormattedAddress = async (lat, lng) => {
  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_API_MAPS}`;
  
  try {
    const response = await fetch(geocodeUrl);
    const data = await response.json();

    if (data.status === 'OK') {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      console.error('Error al obtener la dirección:', data.status);
      return 'Dirección no disponible';
    }
  } catch (error) {
    console.error('Error al hacer la solicitud a la API de Google Maps:', error);
    return 'Dirección no disponible';
  }
};

const handleLocationClick = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        setSelectedPosition({lat, lng});
        setCurrentPosition({lat, lng});

      },
      () => {
        alert('Error al obtener la ubicación');
      }
    );

  } else {
    alert('Geolocalización no soportada por este navegador');
  }
};


  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      const selectedAddress = {
        address: place.formatted_address,
        lat,
        lng
      };

      setAddressInfo(selectedAddress);
      setCurrentPosition({lat, lng});
      setSelectedPosition({lat, lng});
      onSelect( selectedAddress);

    }
  };


  return (
    <>

      <LoadScript googleMapsApiKey={process.env.REACT_APP_API_MAPS} libraries={['places']}>
        <Autocomplete onLoad={ref => (autocompleteRef.current = ref)} onPlaceChanged={handlePlaceChanged}>
          <Input type="text" placeholder="Buscar dirección..." />
        </Autocomplete>

        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={15}
          center={currentPosition}
          onClick={handleMapClick}
        >
          {selectedPosition && <Marker position={selectedPosition} />}
        </GoogleMap>
      </LoadScript>

      <Button className="mt-3" onClick={handleLocationClick}>Obtener ubicación actual</Button>
      {children}

      {addressInfo && (
        <div className="mt-4">
          <h5>Dirección seleccionada:</h5>
          <span>{addressInfo.address}</span>

        </div>
      )}
    </>
  );
}
