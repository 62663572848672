/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Card, CardBody} from 'reactstrap';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import Navbar from './navbar';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid'; // for time view
import {appointmentApi} from '../../../api';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';


export default function UserIndex({mode="admin", containerClass="container-fluid"}) {
  
  const navigate = useNavigate();
  const { Iam, user} = useAuth();

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    let params = {}

    if (Iam('barbero')) {
      params.user_id = user.id;
    }

    if (Iam('cliente')) {
      params.client_id = user.id;
    }

    appointmentApi.get(params).then(res => {
      console.log(res.data.data);
      setAppointments(res.data.data.map(appointment => {
        return {
          id: appointment.id,
          title: appointment.status,
          start: `${appointment.date}T${appointment.time}`, // Hora de inicio
          end: `${appointment.date}T${appointment.end_time}`, // Hora de fin
          allDay: false,
        };
      }));
    });
  }, []);

  const eventClick = (info) => {
    navigate(info.event.id);
  }

  return (
    <>
      <Navbar mode={mode} />

      <div className={containerClass}>
        <Card>
          <CardBody>
            <FullCalendar
              locale="es"
              eventClick={eventClick}
              events={appointments}
              plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
              initialView="timeGridWeek" // Mostrar vista de semana con horas
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listDay' // Opciones de vista
              }}
              slotMinTime="08:00:00" // Hora mínima para mostrar
              slotMaxTime="20:00:00" // Hora máxima para mostrar
            />
          </CardBody>
        </Card>
      </div>
    </>
  );
}
