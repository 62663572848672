import React from 'react'            
import './about.styles.scss';
import logo from '../../../assets/logo.png';
import img1 from '../../../assets/img1.jpg';
import img2 from '../../../assets/img2.jpg';
import img3 from '../../../assets/img3.jpg';

export default function About()
{

	return  (<section id="about" className="about_section py-10 bd-bottom padding">
            <div className="container">
               <div className="row">
                   <div className="col-lg-6">
                        <div className="about_content text-center">
                            <h3 className="wow fadeInUp" data-wow-delay="100ms">Nosotros</h3>
                            <h2 className="wow fadeInUp" data-wow-delay="200ms">LA NUEVA GENERACIÓN DE BARBEROS</h2>
                            <img className="wow fadeInUp" data-wow-delay="500ms" width="200px" src={logo} alt="logo" />
                            <p className="wow fadeInUp" data-wow-delay="600ms">En Corte nuestra visión es cambiar el paradigma de la industria. La barbería es algo personal y no debe conllevar un esfuerzo adicional. Nosotros abogamos por el respeto, seguridad, limpieza, excelencia y profesionalidad; a tus tiempos, disposición y lugar.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 d-none d-md-block">
                        <div className="about_img">
                            <img src={img1} alt="idea-images" className="about_img_1 wow fadeInLeft" data-wow-delay="200ms" />
                            <img src={img2} alt="idea-images" className="about_img_2 wow fadeInRight" data-wow-delay="400ms" />
                            <img src={img3} alt="idea-images" className="about_img_3 wow fadeInLeft" data-wow-delay="600ms" />
                        </div>
                    </div>
               </div>
            </div>
        </section>
    )
        
}