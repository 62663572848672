import React from 'react'
import { Card, CardBody, CardTitle} from 'reactstrap';
import Map from '../../../../../components/map'
export default function Show({index=1, address, Delete=false, dispatchable}) {





  return (
      <Card key={index} >
            <CardBody>
              <CardTitle tag="h5">
                {address.address}  {Delete && <Delete id={address.id} dispatchable={dispatchable}/>}
              </CardTitle>
                    <div >
                    {address.city} {address.state}, {address.cp}
                  </div>

                  {address.map && Map(address)}


            </CardBody>
          </Card>

          )
}
