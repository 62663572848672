import React from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
// import SubMenu from "./submenu";
import { FaUser, FaFolderTree, FaScissors, FaCalendar, FaGears } from 'react-icons/fa6';
import './styles.scss';
import {useSelector} from 'react-redux';

const AllMenu = [
  {
    title:'Usuarios',
    route:'/admin/usuarios',
    icon:FaUser,
    role:['administracion']
  },
  {
    title:'Categorias',
    route:'/admin/categorias',
    icon:FaFolderTree,
    role:['administracion']
  },
  {
    title:'Servicios',
    route:'/admin/servicios',
    icon:FaScissors,
    role:['administracion', 'barbero']
  },
  {
    title:'Citas',
    route:'/admin/citas',
    icon:FaCalendar,
    role:['administracion', 'barbero']
  },
  {
    title:'Configuracion',
    route:'/admin/configuracion',
    icon:FaGears,
    role:['administracion', 'barbero']
  },
]


const Icon = ({Icon}) => <Icon className="mr-2 " />

const SideBar = ({ isOpen, toggle }) => {


  const navigate = useNavigate();

  const {user} = useSelector(state=>state.auth);

  const goto = (route) =>{
    navigate(route);
  }


  const menus = AllMenu.filter((menu)=>menu.role.includes(user?.role?.name)); 


  return (
  <div className={classNames("sidebar", { "is-open": isOpen })}>
    <div className="sidebar-header">
      <span color="info" onClick={toggle} style={{ color: "white" }}>
        &times;
      </span>
      <h3>Administracion</h3>
    </div>
    <div className="side-menu">
      <Nav vertical className="list-unstyled pb-3">
        {/*<p>Dummy Heading</p>*/}
        {/*<SubMenu title="Home" icon={''} items={submenus[0]} />*/}
       {menus.map((menu, index)=>(

        <NavItem  className="w-100"  key={index} >
          <NavLink  className="w-100" onClick={()=>goto(menu.route)}>
          {menu.icon ?   <Icon Icon={menu.icon} /> : null}
            {menu.title}
          </NavLink>
        </NavItem>

        ))}
      </Nav>
    </div>
  </div>
);

}


export default SideBar;
