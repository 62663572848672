import types from "./actionTypes";
import Token from "../../config/token";
import { authApi } from "../../api";
// import {toast} from 'react-toastify';

export const login = (data) => async (dispatch) => {
  let response = await authApi.login(data);

  if (response.data.status) {
    let token = response.data.data;
    let user = Token.decode(token).sub;

    dispatch(types.login(user));
    Token.set(token);

    let to = localStorage.getItem("next-route") || "/";
    localStorage.removeItem("next-route");
    window.location.href = to;
  }
};

export const register = (data) => async (dispatch) => {
  let response = await authApi.register(data);
  if (response.data.status) {
    let token = response.data.data;
    let user = Token.decode(token).sub;

    dispatch(types.login(user));
    Token.set(token);

    let to = localStorage.getItem("next-route") || "/perfil";
    localStorage.removeItem("next-route");
    window.location.href = to;
  }
};

export const check = () => async (dispatch) => {
  let response = await authApi.check();
  dispatch(types.login(response.data.data));
  return response;
};

export const logout = () => async () => {
  Token.destroy();
  localStorage.clear();
  authApi.logout();
  window.location.href = "/";
};
