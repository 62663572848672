/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useParams}  from 'react-router-dom';
import Navbar from '../../../../components/navbar';

export default function  NavbarComponent({mode="admin"}) {
  
  const {id} = useParams()
  const routes= mode==="admin" ?[
    {name:'Informacion', 'route':`/admin/usuarios/${id}`},
    {name:'Direcciones', 'route':`/admin/usuarios/direcciones/${id}`},
  ] : 
  [
    {name:'Informacion', 'route':`/perfil`},
    {name:'Direcciones', 'route':`/perfil/direcciones`},
  ]
  
  return (
    <>
    <Navbar routes={routes} />
    </>
  )
}
