
import {categoryApi} from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';



export const getCategoriesAsync = (params) => async (dispatch) => {
    const response = await categoryApi.get(params)
    dispatch(types.setCategories(response.data.data));
}

export const saveCategoryAsync = (data) => async (dispatch,state)=>{
    const response = await categoryApi.save(data);
    toast.success('Categoria guardado correctamente', {"theme":"dark"})
    let categories = JSON.parse(JSON.stringify(state().category.categories));

    categories.data.push(response.data.data);
    dispatch(types.setCategories(categories));

}


export const updateCategoryAsync = (row_id, data) => async (dispatch,state)=>{
    const response = await categoryApi.update(row_id, data);
    toast.success('Categoria guardado correctamente', {"theme":"dark"})
    let categories = JSON.parse(JSON.stringify(state().category.categories));

    categories.data = categories.data.map(row=>{

        if(row.id===row_id) {
            console.log('id')
            row = {
                ...row,
                ...response.data.data,
   
            }
        }
       
        console.log(row)
        return row

    });
    dispatch(types.setCategories(categories));

}

export const deleteCategoryAsync = (row_id) => async(dispatch, getState) => {
    await categoryApi.delete(row_id);
    let currentState = JSON.parse(JSON.stringify(getState().category.categories));

    currentState.data = currentState.data.filter(row=>row.id!==row_id);

    toast.success('Categoria eliminada correctamente', {'theme':"dark"});
    dispatch(types.setCategories(currentState))
}




export const updateCategoryImageAsync = (row_id, data) => async (dispatch,state)=>{
    const response = await categoryApi.updateImage(row_id, data);
    toast.success('Imagen editada correctamente', {"theme":"dark"})
    let categories = JSON.parse(JSON.stringify(state().category.categories));

    categories.data = categories.data.map(row=>{

        if(row.id===row_id) {
            console.log('id')
            row = {
                ...row,
                ...response.data.data,
   
            }
        }
       
        console.log(row)
        return row

    });
    dispatch(types.setCategories(categories));

}


