/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Modal from "../../../../components/modal";
import Builder from "../../../../components/forms";
import { Button } from "reactstrap";
import useAuth from "../../../../hooks/useAuth";

export const CreateFee = ({ saveService }) => {
  const [fields, setFields] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { Iam, user } = useAuth();

  useEffect(() => {
    if (!user?.id) return;

    let extra = [];

    if (Iam("administracion")) {
      extra.push({
        name: "active",
        label: "Activo",
        boolean: true,
      });
    }

    console.log(Array.from({ length: 13 }, (_, index) => (index + 1) * 10));
    setFields([
      {
        name: "price",
        label: "Precio",
        defaultValue: "",
        required: true,
      },
      {
        name: "title",
        label: "Titulo",
        defaultValue: "",
        required: true,
      },
      ...extra,
      {
        name: "minutes",
        label: "Minutos de duración",
        options: Array.from({ length: 24 }, (_, index) => (index + 1) * 5).map(
          (option) => (
            <option key={option} value={option}>
              {option}
            </option>
          )
        ),
        required: true,
      },
      {
        name: "description",
        label: "Descripcion",
        defaultValue: "",
        required: true,
        textarea: true,
      },
    ]);
  }, [user]);

  return (
    <>
      <Button onClick={() => setModalOpen(true)}> Crear tarifa</Button>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={"Crear tarifa"}
      >
        <Builder
          fields={fields}
          message="Crear Tarifa"
          onClick={(res) => {
            saveService(res);
            setModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
