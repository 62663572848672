import React, { Fragment, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Table } from "reactstrap";
import useOrder from "./useOrder";
import Pagination from "./paginate";

import "./styles.scss";

const DataTables = ({
  columns,
  dispatchable,
  selectable,
  title = "Lista",
  fire = true,
}) => {
  const dispatch = useDispatch();
  const goDispatch = useRef(dispatchable);

  useEffect(() => {
    goDispatch.current = dispatchable;
  }, [dispatchable]);

  const data = useSelector(selectable);
  let [search, setSearch] = useState("");

  const [rows, setRows] = useState([]);
  const [thead, setThead] = useState([]);
  const [numberPage, setNumberPage] = useState(1);
  const [timer, setTimer] = useState(null);
  let { makeOrder } = useOrder(rows, setRows);

  const searchChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      setSearch(e.target.value);
    }, 1000);

    setTimer(newTimer);
  };

  useEffect(() => {
    let query = {};
    if (numberPage) query.page = numberPage;

    if (search.trim() !== "") query.s = search.trim();

    fire && dispatch(goDispatch.current(query));
  }, [numberPage, search, fire, dispatch, goDispatch]);

  useEffect(() => {
    if (data?.data !== undefined) {
      let dataMapped = data.data?.map(columns) || [];
      let heads = [];

      for (let k in dataMapped[0]) {
        heads.push({
          key: k.toString().replaceAll(" ", "_"),
          name: k.toString().toUpperCase().replaceAll("_", " "),
        });
      }

      setThead(heads);
      setRows(dataMapped);
    }
  }, [data, columns]);

  const handleChange = (newPage) => {
    setNumberPage(newPage);
  };

  const renderTable = () => {
    const _rows = rows.map((row, index) => (
      <tr key={"tbody-tr-" + index}>
        {thead.map((column) => (
          <td key={"tbody-tr-td-" + column.key}>{row[column.key]}</td>
        ))}
      </tr>
    ));

    if (_rows.length < 1) {
      _rows.push(
        <tr key={"tbody-tr-td-0"}>
          <td>No hay informacion</td>
        </tr>
      );
    }

    return _rows;
  };

  return (
    <Fragment>
      <div className="table-container">
        <p style={{ padding: "1rem" }}>{title} </p>

        <div className="row m-0 justify-content-between">
          <Input
            style={{ width: "60%" }}
            placeholder="Busca un texto"
            onKeyUp={searchChanged}
          />
          <Pagination
            count={data?.pages || 0}
            page={numberPage}
            onChange={handleChange}
          />
        </div>

        <div className="overflow-x-scroll">
          <Table striped>
            <thead>
              <tr>
                {thead.map((column) => (
                  <th
                    onClick={() => makeOrder(column.key)}
                    key={"thead-tr-" + column.key}
                  >
                    {column.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </Table>
        </div>
      </div>
    </Fragment>
  );
};

export default DataTables;
