import bg from '../assets/bg.jpg'

  
export default function Login({children})
{


	return  <div style={{backgroundImage:`url(${bg})`, height:'100vh', backgroundSize:'cover'}}>
      <div className="h-100" style={{backgroundColor:"#222020cc"}}>
   
    <div className="container h-100" >
		{children}    	
	</div>
	</div>
	</div>


}