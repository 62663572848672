import {authAxios} from './../config/axios';

class Appointment{
    get=(params={})=>{
        return authAxios.get('/appointments', {
            params
        });
    }   


    show=(id)=>{
        return authAxios.get(`/appointments/${id}`);
    }

    save=(data)=>{
        return authAxios.post('/appointments', data);
    }

    update=(id,data)=>{
        return authAxios.put('/appointments/'+id, data);
    }

    times=(user, date)=>{
        return authAxios.get(`/appointments/${user}/times/${date}`);
    }


    updateStatus=(id,status)=>{
        return authAxios.put(`/appointments/${id}/${status}`);
    }

    delete=(id)=>{
        return authAxios.delete(`/appointments/${id}`);
    }   
  
}

const appointment= (new Appointment());
export default appointment;