import React from 'react';
import {  Modal, ModalHeader, ModalBody } from 'reactstrap';

export default function  ModalComponent({modalOpen, title, setModalOpen, children}) {


  const toggle=()=> {
    setModalOpen(!modalOpen)
  }

    return (
      <div>
        <Modal className="modal-lg" isOpen={modalOpen} toggle={setModalOpen} >
          <ModalHeader toggle={toggle}>{title}</ModalHeader>
          <ModalBody>
          

              {children}

          </ModalBody>
        </Modal>
      </div>
    );

}
