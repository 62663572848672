import axios, {authAxios} from './../config/axios';

class Auth{
	register=(data)=>{
		return axios.post('auth/register',data);
	}

	login=(data)=>{
		return axios.post('auth/login',data);
	}

	check=()=>{
		return authAxios.post('auth/check');
	}

	logout=()=>{
		return authAxios.post('auth/logout');
	}	

	recoverPassword=(data)=>{
		return axios.post('auth/recover-password',data)
	}

	changePassword=(data)=>{
		return axios.post('auth/change-password',data)
	}

	sendVerification=(email)=>{
		return axios.post('auth/send-email-verification', {
			email
		})
	}

	verifyEmail=(token)=>{
		return axios.post(`auth/verify/${token}`)
	}
}

const auth = (new Auth());
export default auth;
