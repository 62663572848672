import React, {useState, useEffect} from 'react'
import { useParams} from 'react-router-dom';
import { getAddressAsync, deleteAddressAsync} from '../../../../../redux/user/actions';
import {useDispatch, useSelector} from 'react-redux';
import Navbar from '../navbar';
import {Create} from './Create';
import {Delete} from '../../../../../components/buttons';
import CardAddress from './CardAddress';

export default function Show({mode="admin", containerClass="container-fluid"}) {

  const {id: paramId} = useParams();
  const [id, setId] = useState(null);
  const dispatch = useDispatch();

  const {addresses} = useSelector((state)=>state.user);
  const {user:auth} = useSelector((state)=>state.auth);


  useEffect(()=>{
    mode==="admin" ? setId(paramId) : setId(auth.id) ;
  },[auth, mode, paramId])

  useEffect(()=>{
    dispatch(getAddressAsync({
      user_id:id
    }))


    console.log(addresses)
  },[id, dispatch])



    return (

<>
       <Navbar  mode={mode}/>
 
      <div className={containerClass}>

      <div className="row">

       

        <Create user_id={id} />
        <div className="col-md-12 mt-4">

        {addresses.map((address,index)=>(

          <CardAddress Delete={Delete} dispatchable={deleteAddressAsync} index={index} address={address} />

                ))}

        {addresses.length <1 ? <p>No hay direcciones</p> : null}
          

          </div>
          </div>
          </div>

    </>
      )
}
