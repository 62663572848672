import { FaAngleLeft , FaAngleRight} from 'react-icons/fa6';
import './paginate.styles.scss';

const Paginate = ({count,page,onChange}) =>  {

   

  const current = (number)=>{

    if(page===number) {
      return 'current';
    }

    return '';

  }
  const renderBody=()=>{

    const buttons = [];

    let  min = Math.min(count || 0, 5)


      buttons.push(<span className={'page '+current(1)} key={'paginate-1'} onClick={()=>onChange(1)}>1</span>);

    if(page< 5 || count<=5) {

        for (let k in [...Array(min).keys()]){
          let j = parseInt(k)+1;

          if(j===1) continue;
          buttons.push(<span className={'page '+current(j)} key={'paginate-'+j} onClick={()=>onChange(j)}>{j}</span>);

        }
    

      if(min!==count) {
          buttons.push(<span className="dot" key={'paginate-first-dot'} >...</span>);
      
      }  
      

      if(count<=5) {
        return buttons;
      }
      
    } else if( page<count-3) {

      
          buttons.push(<span className="dot" key={'paginate-first-dot'} >...</span>);


          const p =[page-1, page, page+1];
          for (let k in p ) {
            let i= p[k]
            buttons.push(<span className={'page '+current(i)} key={'paginate-'+i} onClick={()=>onChange(i)}>{i}</span>);
          }
        

          if(page<(count-2)) {
            buttons.push(<span   className="dot" key={'paginate-end-dot'} >...</span>);
      
          }   
      
    } else {
          buttons.push(<span className="dot" key={'paginate-first-dot'} >...</span>);

          const p = [count-3, count-2, count-1];
          for (let k in p) {
            let i = p[k]
            buttons.push(<span className={'page '+current(i)} key={'paginate-'+i} onClick={()=>onChange(i)}>{i}</span>);

          }

         
    }



    buttons.push(<span className={'page '+current(count)} key={'paginate-'+count} onClick={()=>onChange(count)}>{count}</span>);
  
      
      return buttons

  }



  const changeLeft = ()=>{
    onChange(page-1);
  }

  const changeRight = ()=>{
    onChange(page+1);
  }


  return <div className="paginate" style={{width:'40%'}}>
    {page>1 ?   <span className="arrow" key={'paginate-'} onClick={changeLeft}><FaAngleLeft/></span> : null}

    {count? renderBody() : '...'}

    {page<count ? <span className="arrow" key={'paginate-'} onClick={changeRight}><FaAngleRight/></span>: null}

  </div>
}

export default Paginate;