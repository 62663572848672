import {useEffect, useState} from 'react';
import {authApi} from '../../api';
import './styles.scss';
import {useParams,useNavigate} from 'react-router-dom';

function App() {

  const {token} = useParams();
  const navigate = useNavigate();
  const [text, setText] = useState('verificando...');
  

  useEffect(()=>{
    authApi.verifyEmail(token).then(res=>{
       navigate('/')
      }).catch(res=>{
      setText('El correo ha expirado')
    });
  }, [token, navigate])


  return (
    <>
    
    <div className="container " style={{height:'400px'}}>
    <div className="row align-items-center justify-content-center h-100">
    <div class="col ta-center text-center">
    <h1>{text}</h1>
    </div>
    </div>
      
    </div>
    </>
  );
}

export default App;
