import { useSelector } from "react-redux";

const useAuth = () => {
  const { user } = useSelector((state) => state.auth);

  const Iam = (role) => {
    return user?.role?.name === role;
  };

  const AdminOrMe = (Or = null) => {
    return user?.role?.name === "administracion" ? Or : user.id;
  };

  return {
    user,
    Iam,
    AdminOrMe,
  };
};

export default useAuth;
