import Show from '../../admin/appointments/Show';
function App() {


  return (
    <>
      <Show mode="web" containerClass="container my-5" />
  
    </>
  );
}

export default App;
