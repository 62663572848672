import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { serviceApi, categoryApi } from "../../../api";
import { toast } from "react-toastify";
import Builder from "../../../components/forms";
import { Card, CardBody } from "reactstrap";
import InputFile from "../../../components/forms/InputFile";
import useAuth from "../../../hooks/useAuth";

export default function Edit() {
  const { id } = useParams();
  const [service, setService] = useState({});
  const [fields, setFields] = useState([]);
  const [categories, setCategories] = useState([]);
  const [userSelected, setUserSelected] = useState(false);
  const { Iam, user } = useAuth();

  useEffect(() => {
    if (!user?.id) return;

    let extra = [];

    if (Iam("administracion")) {
      extra.push({
        name: "active",
        label: "Activo",
        boolean: true,
      });
    }

    service.id &&
      setFields([
        {
          name: "name",
          label: "Nombre",
          defaultValue: service.name,
        },
        ...extra,
        {
          name: "description",
          label: "Descripcion",
          textarea: true,
          defaultValue: service.description,
        },
        {
          name: "category_id",
          label: "Categoria",
          defaultValue: service.category_id,
          required: true,
          options: categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          )),
        },
      ]);
  }, [service, categories]);

  useEffect(() => {
    if (id) {
      serviceApi.show(id).then((response) => {
        setService(response.data.data);
      });
    }
  }, [id]);

  useEffect(() => {
    categoryApi.get().then((res) => setCategories(res.data.data));
  }, []);

  const saveService = (data) => {
    if (userSelected) {
      data.user_id = userSelected;
    }

    serviceApi.update(service.id, data).then((response) => {
      setService(response.data.data);
      toast.success(response.data.msg, { theme: "dark" });
    });
  };

  const changeImage = (e) => {
    serviceApi.changeImage(service.id, e.target.files[0]).then((res) => {
      setService(res.data.data);
    });
  };

  const onChangeUser = (userS) => {
    console.log(userS);
    setUserSelected(userS);
  };
  return (
    <div className="row">
      <div className="col-md-8">
        <Card>
          <CardBody>
            <p className="h2">Editar servicio</p>

            <Builder fields={fields} onClick={saveService} />
          </CardBody>
        </Card>
      </div>

      <div className="col-md-4">
        <img
          alt=""
          className="img-fluid"
          style={{ margin: "3rem auto" }}
          src={
            service?.image ||
            "https://cdn-icons-png.flaticon.com/512/149/149071.png"
          }
        />

        <InputFile
          accept={["image/png", "image/gif", "image/jpeg", "image/webp"]}
          onChange={changeImage}
        />
      </div>
    </div>
  );
}
