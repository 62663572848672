
import {appointmentApi} from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';



export const getAppointmentsAsync = (params) => async (dispatch) => {
    const response = await appointmentApi.get(params)
    dispatch(types.setAppointments(response.data.data));

}


export const saveServiceAsync = (data) => async (dispatch,state)=>{
    const response = await appointmentApi.save(data);
    toast.success('Cita guardada correctamente', {"theme":"dark"})
    let appointments = JSON.parse(JSON.stringify(state().appointment.appointments));

    appointments.data.push(response.data.data);
    dispatch(types.setAppointments(appointments));

}


export const updateAppointmentStatusAsync = (id, status) => async (dispatch,state)=>{
    await appointmentApi.updateStatus(id, status);
    toast.success('Cita cancelada correctamente', {"theme":"dark"})
    let appointments = JSON.parse(JSON.stringify(state().appointment.appointments));



    appointments.data = appointments.data.map(app=>{

        if(app.id===id) {
            app.status=status
        }

        return app;
    });
    dispatch(types.setAppointments(appointments));

}

