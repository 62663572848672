import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default function  ConfirmDialog({modalOpen, setModalOpen, message, onClick}) {


  const toggle=()=> {
    setModalOpen(!modalOpen)
  }

  const confirm=()=>{
    toggle()
    onClick();
  }

    return (
      <div>
        <Modal isOpen={modalOpen} toggle={setModalOpen} >
          <ModalHeader toggle={toggle}>Confirmar</ModalHeader>
          <ModalBody>
          

              {message}

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancelar</Button>
            <Button color="primary" onClick={confirm}>Confirmar</Button>{' '}
          </ModalFooter>
        </Modal>
      </div>
    );

}
