
import {serviceApi} from '../../api';
import types from './actionTypes';
import {toast} from 'react-toastify';



export const getServicesAsync = (params) => async (dispatch) => {
    const response = await serviceApi.get(params)
    dispatch(types.setServices(response.data.data));

}


export const saveServiceAsync = (data) => async (dispatch,state)=>{
    const response = await serviceApi.save(data);
    toast.success('Servicio guardado correctamente', {"theme":"dark"})
    let services = JSON.parse(JSON.stringify(state().service.services));

    services.data.push(response.data.data);
    dispatch(types.setServices(services));

}


export const deleteServiceAsync = (row_id) => async(dispatch, getState) => {
    await serviceApi.delete(row_id);
    let currentState = JSON.parse(JSON.stringify(getState().service.services));

    currentState.data = currentState.data.filter(row=>row.id!==row_id);

    toast.success('Servicio eliminada correctamente', {'theme':"dark"});
    dispatch(types.setServices(currentState))
}

