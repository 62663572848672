import Appointment from '../../admin/appointments/History';
function App() {


  return (
    <>
      <Appointment mode="web" containerClass="container my-5" />
  
    </>
  );
}

export default App;
