import axios, { authAxios } from "./../config/axios";

class Fee {
  get = (params) => {
    return axios.get("/fees", {
      params,
    });
  };

  save = (data) => {
    return authAxios.post("/fees", data);
  };

  update = (id, data) => {
    return authAxios.put("/fees/" + id, data);
  };
  delete = (id) => {
    return authAxios.delete(`/fees/${id}`);
  };
}

const fee = new Fee();
export default fee;
