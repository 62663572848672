import React, {useState} from 'react'            
import './about.styles.scss';
import './contactForm.styles.scss';
import {Button, Input} from 'reactstrap';
import {contactApi} from '../../../api';

export default function About()
{

    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [n1] = useState(Math.floor(Math.random() * 10));
    const [n2] = useState(Math.floor(Math.random() * 10));

    const [data, setData] = useState({
        name:'',
        email:'', 
        phone:'',
        comment:'',
        kacha:''
    })


    const onChange= (e)=>{
        setData({
            ...data,
            [e.target.name]:e.target.value
        });

    }


    const submit = () =>{


        if(loading) {
            return;
        }
        for(let k in data) {
            if(data[k].toString().trim()===""){
                return;  
          }
        }


        if(n1+n2 !== parseInt(data.kacha)) {
            return;
        }


        setLoading(true);
        contactApi.send(data).then(res=>{
            setShow(false); 
            setLoading(false); 
        }).catch(e=>setLoading(false))

    }

	return  (<section className="book_section padding">
            <div className="container-fluid">
                <div className="row">
                 <div className="col-md-6 book_bg">
                 </div>
                   
                    <div className="col-md-6 offset-md-6 map_pattern p-0">
                    <div className="back-container">
                      {show? <form action="appointment.php" method="post" id="appointment_form" className="form-horizontal appointment_form">
                           <div className="book_content">
                                <h2>Te queremos escuchar</h2>
                                <p className="text-white">Envíanos un correo, queremos conocer tus preocupaciones, <br/>comentarios y apoyarte en lo que necesites.</p>
                           </div>
                                <Input onChange={onChange} type="text" name="name" className="mb-3" placeholder="Nombre" required />
                                 <div className="form-group row">
                           
                                    <div className="col-md-6">
                                     <Input onChange={onChange} type="email"  name="email" className="mb-3" placeholder="Correo" required />
                                    </div>
                                   
                                    <div className="col-md-6">
                                   
                                        <Input onChange={onChange} type="number"  name="phone" className="mb-3" placeholder="Telefono" required />
                                    </div>
                                </div>
                                <Input onChange={onChange} style={{minHeight:'120px'}} type="textarea" name="comment" className="mb-3" placeholder="Comentarios" required />
                                <Input onChange={onChange} type="number" name="kacha" className="mb-3" placeholder={`${n1} + ${n2}`}required />
                          
                           
                            <Button onClick={submit} color="primary" disabled={loading} className="mt-3">{loading? 'Enviando': 'Enviar correo'}</Button>
                            <div id="msg-status" className="alert" role="alert"></div>
                        </form>

                        : <div  className="w-100"> <h6  className="w-100 text-white text-center">Mensaje enviado correctamente</h6></div>}
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
        
}