import bg from '../../../assets/bg.jpg'
import bg2 from '../../../assets/bg2.jpg'
import bg3 from '../../../assets/bg3.jpg'
import './hero.styles.scss';
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Button} from 'reactstrap';
import {useNavigate} from 'react-router-dom';


              
     


export default function Hero()
{


	const navigate = useNavigate();
	return (	<section className="slider_section main_slider">
    

<OwlCarousel 
	className='owl-carousel'
	loop={true}
	//autoplay={true}
	smartSpeed={500}
	items={1}
	nav
	navText={['<', '>']}
	>
     <li className="main_slide d-flex align-items-center" style={{backgroundImage:`url(${bg})`, height:'80vh', backgroundSize:'cover'}}>
                    <div className="back-container">
                   
                    <div className="container">
                        <div className="slider_content">
                            <h3>No es simple corte, es una experiencia.</h3>
                            <h1>CONOCE A TU BARBERO <br/>Y OBTÉN UN SERVICIO A DOMICILIO</h1>
                            <p>En Corte buscamos darte una experiencia segura, atractiva, amistosa e impecable a las puestas de tu hogar.</p>
              			    	<Button onClick={()=>navigate('/profesionales')} color="primary" >Agenda tu cita</Button>
               					<Button onClick={()=>navigate('/registro')} color="secondary" size="sm" >Crea tu cuenta</Button>
                        </div>
                    </div>
                    </div>
                </li>
                <li className="main_slide d-flex align-items-center"  style={{backgroundImage:`url(${bg2})`, height:'80vh', backgroundSize:'cover'}}>
           <div className="back-container">
                 
                    <div className="container">
                        <div className="slider_content">
                            <h3>Tú eliges al barbero, el más adecuado para ti.</h3>
                            <h1>CUÁL ES TU BARBERO IDEAL ¿BUSCAS A UN AMIGO? ¿BUSCAS A UN ESPECIALISTA? TÚ DECIDES</h1>
                            <p>Nuestro objetivo es crear una experiencia hecha para ti, nuestros barberos, su experiencia y su excelencia nos respalda.</p>
             			    	<Button onClick={()=>navigate('/profesionales')} color="primary" >Agenda tu cita</Button>
               					<Button onClick={()=>navigate('/registro')} color="secondary" size="sm" >Crea tu cuenta</Button>
                         </div>
                        </div>
                    </div>
                </li>
                <li className="main_slide d-flex align-items-center" style={{backgroundImage:`url(${bg3})`, height:'80vh', backgroundSize:'cover'}}>
            <div className="back-container">
    
                    <div className="container">
                        <div className="slider_content">
                            <h3>Barbero, servicio, día, hora y lugar.</h3>
                            <h1>SELECCIONA AL BARBERO <br/> ELIGE EL SERVICIO Y ACOMÓDALO COMO TE CONVENGA</h1>
                            <p>Queremos darte un servicio hecho a la medida, tu tiempo es oro. Inicia sesión, agenda tu cita y entra en contacto con tu barbero.</p>
             			    	<Button onClick={()=>navigate('/profesionales')} color="primary" >Buscar</Button>
               					<Button onClick={()=>navigate('/registro')} color="secondary" size="sm" >Crea tu cuenta</Button>
                         </div>
                        </div>
                    </div>
                </li>
           
</OwlCarousel>

   </section>
   )
}