/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect} from 'react'
import {Input, CardBody, Card} from 'reactstrap';
import {Confirm} from '../../../../components/buttons';
import {appointmentApi} from '../../../../api';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';

export default function UserIndex({mode="admin", data}) {


  const [total, setTotal] = useState(0);
  const [comments, setcomments] = useState('');

  useEffect(()=>{

    let _total=0
    data.fees.map(fee=>_total+=parseFloat(fee.price));

    setTotal(_total);
  },[data])

    const navigate= useNavigate();

    const clear = (text) =>{
      return text.replaceAll(/(<([^>]+)>)/ig," ")

    }


  function clearTime(time12h) {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    if (modifier === 'PM' && hours !== 12) {
        hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
        hours = 0;
    }
    if (minutes.length < 2) {
        minutes = `0${minutes}`;
    }
    const hoursStr = hours < 10 ? `0${hours}` : `${hours}`;
    return `${hoursStr}:${minutes}`;
}

const submit = ()=>{
  appointmentApi.save({
    'date': data.date,
    'time': clearTime(data.time),
    'client_id': data.client?.id, 
    'address': data.address, 
    'fees':data.fees.map(fee=>fee.id),
    'description': comments

  }).then(res=>{


    toast.success("cita creada corectamente");
    localStorage.removeItem('fee');
    localStorage.removeItem('service');
    localStorage.removeItem('barber');
    localStorage.removeItem('next-route');

    navigate(mode==="admin"? '/admin/citas': '/citas');




  })
}
    return (

      <>

        <Card>
          <CardBody>

            <p><span className="fw-bold">Barbero:</span> {data.barber.fullName}</p>

            <p><span className="fw-bold">Dia:</span> {data.date}</p>
            <p><span className="fw-bold">Hora:</span> {data.time}</p>
            {data?.client.email && <p><span className="fw-bold">Cliente:</span> {data.client.email }</p> }
            <p><span className="fw-bold">Direccion:</span> {data.address?.address || 'Ninguna'}</p>
            {
              data?.fees?.map(fee=>(
                <div className="m-3">
                   <p><span className="fw-bold">Servicio:</span> {fee?.service.name}</p>
                   <p><span className="fw-bold">tarifa:</span> {clear(fee?.description)}</p>
            <p><span className="fw-bold">Tarifa:</span> ${fee.price}</p>
            <hr/>
            </div>
              ))
            }

            <h3>Total <b>${total}</b></h3>

            <p>Comentarios:</p>
            <Input type="textarea"  value={comments} onChange={(e)=>setcomments(e.target.value)} />


            <Confirm text="Crear cita" onClick={submit}/>

          </CardBody>

        </Card>
      
      </>
    )
  
}