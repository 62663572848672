/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { appointmentApi, metadataApi } from "../../../../api";

export default function UserIndex({ data, setData, next }) {
  const [times, setTimes] = useState([]);

  useEffect(() => {


    appointmentApi
      .times(data?.barber?.id, data["date"])
      .then((res) => {
        console.log(res)
        setTimes(res.data.data);
      });
  }, []);


  const selectTime =(time)=>{


    if(time.disabled) {
      return true;
    }

    setData({
      ...data,
      "time":time.time,
    })

    next()

  }
  return (
    <>
      <h3 className="mb-4">Selecciona una hora</h3>

      {data["time"]}
    <div className="times">
      {times.map(time=>(<p  className={(time.disabled&& 'disabled ') +  (time.current&&'current'  )} onClick={()=>selectTime(time)}>{time.label}</p>))}

      </div>
    </>
  );
}
