/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import Navbar from '../../../components/navbar';

export default function  NavbarComponent({mode="admin"}) {
  
  const routes= mode==="admin"?[
    {name:'Calendario', 'route':`/admin/citas`},
    {name:'Nueva cita', 'route':`/admin/citas/nueva`},
    {name:'Historial', 'route':`/admin/citas/historial`},
  ]:[
    {name:'Calendario', 'route':`/citas`},
    {name:'Nueva cita', 'route':`/citas/nueva`},
    {name:'Historial', 'route':`/citas/historial`},
  ];
  
  return (
    <>
    <Navbar routes={routes} />
    </>
  )
}
