import React from 'react'
import {BrowserRouter, Routes,Route} from 'react-router-dom'
import CheckLogin from '../components/checkLogin'
import {guest} from './guest';
import {admin} from './admin';
import {web} from './web';
import NotFound from '../pages/web/404';


const RoutesComponent = () =>  {

  const login = CheckLogin();


  const routes = [
    ...admin,
    ...guest,
    ...web,
  ].filter(route=>{

    if(route.role===false && login===false) {
      return true;
    } else if(route.role?.length===0) {
      return true;
    } else if(route.role && route?.role?.includes(login?.role?.name)) {
      return true;
    }

    return false;
  });


  return(   
       <BrowserRouter basename={`/`}>

             <Routes>
              {routes.map( ({path,Component, Layout, Children=false}) => (
                <Route key={path}   path={path} element={
                  <Layout>
                  <Component />
                  </Layout>
                } >

                { Children && Children.map(({path: pathChildren, Element, data={}}) => {
                  return <Route key={pathChildren}  path={pathChildren} element={<Element data={data} />} />
                })}
                </Route>         


              ))}
              <Route  path="/*" element={<NotFound  />} />
            </Routes>

               
         </BrowserRouter>
    )
}

export default RoutesComponent;