/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input } from "reactstrap";
import { serviceApi } from "../../../../api";
import useAuth from "../../../../hooks/useAuth";
import { feeApi } from "../../../../api";
import CardFee from "../../services/Show/CardFee";
import { toast } from "react-toastify";

export default function UserIndex({ data, setData, next }) {
  const { user, Iam } = useAuth();

  const [services, setServices] = useState([]);
  const [fees, setFees] = useState([]);

  const [empty, setEmpty] = useState(false);
  useEffect(() => {
    let barber_id = data?.barber?.id;

    let params = {
      active: 1,
      with: "barber",
    };

    if (barber_id) {
      params.user_id = barber_id;
    }

    serviceApi.get(params).then((res) => {
      setServices(res.data.data);
    });
  }, [user]);

  const onChange = (e) => {
    let id = e.target.value;

    if (Iam("administracion")) {
      setData({
        ...data,
        barber: services.filter((service) => service.id === id)[0].barber,
      });
    }

    feeApi
      .get({
        active: 1,
        service_id: id,
        with: "service",
      })
      .then((res) => {
        setFees(res.data.data);
        if (res.data.data.length) {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      });
  };

  const selectFee = (fee) => {
    let fees = data.fees;
    fees.unshift(fee);

    toast.success("Tarifa agregada");
    setData({
      ...data,
      fees,
    });
  };
  return (
    <div>
      <h4 className="mb-4">Selecciona un servicio</h4>
      <Input type="select" onChange={onChange}>
        <option disabled selected>
          --Selecciona un servicio--
        </option>
        {services.map((service) => (
          <option value={service.id} key={service.id}>
            {" "}
            {service.name} | {service.barber.fullName}{" "}
          </option>
        ))}
      </Input>

      {fees.map((fee) => (
        <CardFee
          confirm={false}
          key={fee.id}
          fee={fee}
          text="seleccionar"
          onClick={() => selectFee(fee)}
        />
      ))}

      {empty && (
        <p className="alert alert-info mt-3">No hay tarifas en este servicio</p>
      )}
    </div>
  );
}
