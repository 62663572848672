import {authAxios} from './../config/axios';

class Review
{
    save=(data)=>{
        return authAxios.post('/reviews', data);
    }   

     
}

const review  = (new Review());
export default review;
