import Index from '../pages/web/Index';
import Services from '../pages/web/Services';
import ServicesShow from '../pages/web/Services/Show';
import Professionals from '../pages/web/Professionals';
import ProfessionalsShow from '../pages/web/Professionals/Show';
import Profile from '../pages/web/Profile';
import ProfileAddress from '../pages/web/Profile/Address';
import AppointmentIndex from '../pages/web/Appointments';
import AppointmentHistory from '../pages/web/Appointments/History';
import AppointmentCreate from '../pages/web/Appointments/Create';
import AppointmentShow from '../pages/web/Appointments/Show'; 
import Verify from '../pages/web/verify';

import NotFound from '../pages/web/404';

import WebLayout from '../layouts/Web';

export const web=[
        {path:'/', Component:Index, role:[]},
        {path:'/servicios', Component:Services, role:[]},
        {path:'/servicios/:id', Component:ServicesShow, role:[]},
        {path:'/profesionales', Component:Professionals, role:[]},
        {path:'/profesionales/:id', Component:ProfessionalsShow, role:[]},
        {path:'/perfil', Component:Profile, role:['cliente', 'barbero', 'administracion']},
        {path:'/perfil/direcciones', Component:ProfileAddress, role:['cliente', 'barbero', 'administracion']},

        { path:'/citas', Component:AppointmentIndex, role:['cliente','barbero', 'administracion']},
        { path:'/citas/:id', Component:AppointmentShow, role:['cliente','barbero', 'administracion']},
        { path:'/citas/historial', Component:AppointmentHistory, role:['cliente','barbero', 'administracion']},
        { path:'/citas/nueva', Component:AppointmentCreate, role:['cliente','barbero', 'administracion']},

        { path:'/auth/verify/:token', Component:Verify, role:[]},

        {path:'/404', Component:NotFound, role:[]},

].map(route=>{
    route.Layout= WebLayout;
    return route;
})
